class PopUp {
  constructor(element, parent, restore) {
    this.element = element;
    this.parent = parent;
    this.popup = null;
    this.restore = restore;
  }

  open() {
    if (!this.isOpen()) {
      const target = application.pageHandler.contents.element;
      const viewPort = target.getBoundingClientRect();
      const bounds = this.parent.getBoundingClientRect();

      this.popup = document.createElement("div");
      this.popup.classList.add("PopUp");
      this.popup.appendChild(this.element);
      this.popup.style.position = "absolute";
      this.popup.style.top = (target.scrollTop + bounds.bottom - viewPort.top) + "px";
      this.popup.style.left = (target.scrollLeft + bounds.left - viewPort.left) + "px";
      this.popup.style.zIndex = "var(--z-index-pop-up)";

      target.appendChild(this.popup);

      this.popup.addEventListener(
        "keydown",
        (event) => {
          if (event.code === "Escape")
            this.close();
        }
      );

      this.outsideListener = connectClickOutsideListener(
        this.popup,
        (event) => {
          this.close();
        }
      );
    }
  }

  canClose() {
    return application.pageHandler.contents.element.lastChild === this.popup;
  }

  close() {
    if (this.isOpen() && this.canClose()) {
      if (this.restore)
        this.parent.appendChild(this.element);

      application.pageHandler.contents.element.removeChild(this.popup);
      removeClickOutsideListener(this.outsideListener);

      this.popup = null;
    }
  }

  isOpen() {
    return this.popup !== null;
  }
}
