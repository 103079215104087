class DropDown extends FormField {
  constructor(element) {
    super(element);

    this.element.tabIndex = 0;
    this.options = new Array();

    if (this.mode === ControlMode.edit) {
      this.determineElements();
      this.recalculate();
      this.attachHandlers();
    }
  }

  addEventListener(event, handler) {
    if (this.mode === ControlMode.edit)
      this.input.addEventListener(event, handler);
  }

  attachHandlers() {
    this.button.addEventListener("click", (event) => { this.toggleForm(); });
    this.label.addEventListener("click", (event) => { this.toggleForm(); });

    for (const option of this.options) {
      option.element.addEventListener("click", this.createSelectOptionHandler(option));
      option.element.addEventListener("keydown", this.createOptionKeyHandler(option));
    }

    this.element.addEventListener("keydown", this.createKeyHandler());
    this.addValueChangedHandler(this.input);

    this.element.addEventListener(
      "blur",
      (event) => {
        if (!this.dropDownToggle.getStatus() && event.relatedTarget !== this.button && event.relatedTarget !== null)
          this.validate();
      }
    );
  }

  close() {
    this.dropDownToggle.setStatus(false);
    removeClickOutsideListener(this.clickOutsideListener);

    if (!this.element.contains(document.activeElement))
      this.validate();
  }

  createKeyHandler() {
    return (event) => {
      return this.handleKey(event);
    }
  }

  createOptionKeyHandler(option) {
    return (event) => {
      return this.handleOptionKey(option, event);
    }
  }

  createSelectOptionHandler(option) {
    return (event) => {
      this.select(option);
      this.close();
      this.focus();

      return true;
    }
  }

  determineElements() {
    const query = new DomQuery(this.element);

    this.dropDown = query.getChild(WithClass("Options"));
    this.dropDownToggle = new HtmlClassSwitch(this.dropDown, "Expanded");

    this.button = query.getChild(WithTagName("BUTTON"));
    this.button.tabIndex = -1;

    this.label = query.getChild(WithClass("Label"));
    this.input = query.getChild(WithTagName("INPUT"));

    const options = new DomQuery(this.dropDown).getChildren(WithClass("Option"));

    for (const option of options)
      this.options.push(new DropDownOption(option));
  }

  focus() {
    this.element.focus();
  }

  focusNext(option) {
    const target = option.element.nextSibling;

    if (target !== null && target.classList.contains("Option"))
      target.focus();
  }

  focusPrevious(option) {
    const target = option.element.previousSibling;

    if (target !== null && target.classList.contains("Option"))
      target.focus();
  }

  getValue() {
    if (this.mode === ControlMode.edit)
      return this.input.value !== "" ? this.input.value : null;
    else if (this.mode === ControlMode.display)
      return this.element.dataset.Value;
    else
      throw "Unknown control mode: " + this.mode;
  }

  handleKey(event) {
    if (event.ctrlKey && event.code === "Space") {
      this.toggleForm();
      event.stopPropagation();
    }
  }

  handleOptionKey(option, event) {
    if (event.code === "Space" || event.code === "Enter") {
      this.select(option);
      this.close();
      this.focus();

      event.preventDefault();
    }
    else if (event.code === "Escape") {
      this.close();
      this.focus();

      event.stopPropagation();
    }
    else if (event.code === "ArrowUp") {
      this.focusPrevious(option);
      event.preventDefault();
    }
    else if (event.code === "ArrowDown") {
      this.focusNext(option);
      event.preventDefault();
    }
    else if (event.code === "Home") {
      this.options[0].focus();
      event.preventDefault();
    }
    else if (event.code === "End") {
      this.options[this.options.length - 1].focus();
      event.preventDefault();
    }
  }

  isOpen() {
    return this.dropDownToggle.getStatus();
  }

  open() {
    this.dropDownToggle.setStatus(true);
    this.dropDown.scrollIntoView({ block: "nearest", inline: "nearest" });
    this.clickOutsideListener = connectClickOutsideListener(this.element, (event) => { this.toggleForm(); });

    const selected = this.options.find(element => element.selected);

    if (selected !== undefined)
      selected.focus();
    else
      this.options[0].focus();
  }

  recalculate() {
    this.label.innerHTML = "";

    for (const option of this.options) {
      if (option.value === this.input.value) {
        option.selected = true;
        this.label.innerHTML = option.element.innerHTML;
      }
      else
        option.selected = false;
    }
  }

  setValue(value) {
    if (value !== this.input.value) {
      this.input.value = value;
      this.recalculate();

      this.input.dispatchEvent(new Event("change"));
    }
  }

  select(option) {
    if (!option.selected)
      this.setValue(option.value);
  }

  toggleForm() {
    if (this.isOpen())
      this.close();
    else
      this.open();
  }
}

function DropDownOption(element) {
  this.focus = function () {
    this.element.focus();
  }

  Object.defineProperty(this, 'selected', {
    get: function () {
      return this.classSwitch.getStatus();
    },
    set: function (selected) {
      this.classSwitch.setStatus(selected);
      this.element.setAttribute("aria-selected", selected);
    }
  });

  Object.defineProperty(this, 'value', {
    get: function () {
      return this.element.dataset.Value;
    }
  });

  this.element = element;
  this.element.tabIndex = -1;

  this.classSwitch = new HtmlClassSwitch(this.element, "Selected");
}

interactivityRegistration.register("DropDown", function (element) { return new DropDown(element); });
