class Enumeration {
  constructor(constants) {
    this.labels = new Array();

    for (let index in constants) {
      const constant = constants[index];
      this[constant] = index;
      this.labels[index] = constant;
    }
  }

  fromText(value) {
    return this[value];
  }

  toText(value) {
    return this.labels[value];
  }
}
