class RecordSetField extends WebPageComponentClass {
  constructor(element) {
    super(element);

    this.maximumRowIndex = -1;
    this.name = this.element.dataset.Name;
    this.hierarchicalName = this.element.dataset.HierarchicalName;
    this.URI = this.element.dataset.Uri;
    this.determineElements();
    this.rowIndices = this.determineRowIndices();
    this.createElements();
    this.attachRowClickHandlers();
  }

  insertRow() {
    this.maximumRowIndex++;

    const rowIndex = this.maximumRowIndex;
    const xmlRequest = newXmlRequest();
    const commands = "<insertRow index=\"" + rowIndex + "\"/>";

    xmlRequest.open("POST", this.URI, true);
    xmlRequest.setRequestHeader("Content-Type", "application/xml");
    xmlRequest.onreadystatechange = () => { this.handleInsertRowResponse(xmlRequest, rowIndex); }
    xmlRequest.send(commands);
  };

  removeRow(row) {
    this.rowIndices.splice(row.rowIndex - 1, 1);
    this.updateRowIndicesField();

    row.parentNode.removeChild(row);
    this.valueChanged();
  }

  updateRowIndicesField() {
    this.rowIndicesField.value = this.rowIndices.join(", ");
  };

  valueChanged() {
    if (this.rowIndicesField.onchange !== null)
      this.rowIndicesField.onchange();
  }

  handleInsertRowResponse(xmlRequest, rowIndex) {
    if (xmlRequest.readyState == 4) {
      const rowContainer = document.createElement("div");
      rowContainer.innerHTML = "<table>" + xmlRequest.responseText + "</table>";

      const row = rowContainer.getElementsByTagName("tr")[0];
      row.prepend(this.createRemoveButton(row));

      let tBody;

      if (this.table.tBodies.length === 0) {
        tBody = document.createElement("tbody");
        this.table.appendChild(tBody);
      }
      else
        tBody = this.table.tBodies[0];

      tBody.appendChild(row);

      this.rowIndices.push(rowIndex);
      this.updateRowIndicesField();

      interactivityRegistration.attach(row);
      this.valueChanged();
    }
  }

  determineElements() {
    if (this.mode === ControlMode.edit) {
      const form = new DomQuery(this.element).getAncestor(WithTagName("FORM"));
      this.rowIndicesField = form.elements[this.hierarchicalName + ".RowIndices"];
    }
  };

  createInsertButton() {
    const button = document.createElement("button");
    button.classList.add("InsertRow");
    button.type = "button";
    button.addEventListener("click", (event) => { this.insertRow(); });

    const cell = document.createElement("td");
    cell.appendChild(button);

    return cell;
  };

  createRemoveButton(row) {
    const button = document.createElement("button");
    button.classList.add("RemoveRow");
    button.type = "button";
    button.addEventListener("click", (event) => { this.removeRow(row); });

    const cell = document.createElement("td");
    cell.appendChild(button);

    return cell;
  };

  createElements() {
    this.table = this.element.getElementsByTagName("table")[0];

    if (this.mode === ControlMode.edit) {
      this.table.rows[0].prepend(this.createInsertButton());

      for (let index = 1; index < this.table.rows.length; index++) {
        const row = this.table.rows[index];
        row.prepend(this.createRemoveButton(row));
      }
    }
  };

  determineRowIndices() {
    if (this.mode === ControlMode.edit) {
      const value = this.rowIndicesField.value;
      let rowIndexTexts;

      if (value.length > 0)
        rowIndexTexts = value.split(", ");
      else
        rowIndexTexts = new Array();

      const result = new Array(rowIndexTexts.length);

      this.maximumRowIndex = -1;

      for (let index = 0; index < rowIndexTexts.length; index++) {
        const rowIndex = parseInt(rowIndexTexts[index]);
        result[index] = rowIndex;

        if (rowIndex > this.maximumRowIndex)
          this.maximumRowIndex = rowIndex;
      }

      return result;
    }
    else
      return null;
  };

  attachRowClickHandlers() {
    const rows = this.table.tBodies[0].rows;

    for (let index = 0; index < rows.length; index++) {
      const row = rows[index];

      if (row.dataset.Uri !== undefined) {
        const uri = row.dataset.Uri;

        row.addEventListener("click", (event) => { openUrl(event, uri); });
        row.addEventListener("mousemove", (event) => { setHot(row, event, true); });
        row.addEventListener("mouseout", (event) => { setHot(row, event, false); });
      }
    }
  }

  load(value) {
    for (const element of value) {
      // TODO: This will only work when connected.
      this.insertRow();

      // TODO: We should still load the individual fields.
    }
  }

  save() {
    const result = new Array();

    for (const row of this.table.tBodies[0].rows) {
      const value = {};
      const fields = new DomQuery(row).getDescendants(WithClass("Field"));

      for (const field of fields)
        value[field.name] = field.component.save();

      result.push(value);
    }

    return result;
  }

  getProblems(field) {
    const cell = field.element.parentNode;
    return new DomQuery(cell).getChild(WithClass("Problems"));
  }

  addProblem(field, message) {
    const cell = field.element.parentNode;
    const problems = this.getProblems(field);

    if (problems !== null) {
      const problem = document.createElement("span");
      problem.classList.add("Problem");
      problem.classList.add("Error");
      problem.innerText = message;

      problems.appendChild(problem);

      new HtmlClassSwitch(cell, "Error").setStatus(true);
      new HtmlClassSwitch(cell, "Ok").setStatus(false);
    }
  }

  clearProblems(field) {
    const problems = this.getProblems(field);

    if (problems !== null)
      problems.innerHTML = "";

    new HtmlClassSwitch(field.element.parentNode, "Error").setStatus(false);
    new HtmlClassSwitch(field.element.parentNode, "Ok").setStatus(field.value !== null);
  }
}

interactivityRegistration.register("RecordSet", function (element) { return new RecordSetField(element); });
