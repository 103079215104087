function FileDropZone(element) {
  DropZone.call(this, element);

  this.canDrop = function (event) {
    if (event.dataTransfer.types) {
      for (var i = 0; i < event.dataTransfer.types.length; i++) {
        if (event.dataTransfer.types[i] === "Files" || event.dataTransfer.types[i] === "file")
          return true;
      }
    }

    return false;
  }

  this.handleDrop = function (event) {
    var object = this;

    if (event.dataTransfer.files.length > 0)
      this.handleFiles(event.dataTransfer.files);
    else
      retrieveFile(event.dataTransfer.getData("file"), event.dataTransfer.getData("filename"), function (file) { object.handleFiles([file]) });
  }

  this.validateFile = function (file, accept) {
    var valid;

    if (accept.length > 0) {
      var allowedTypes = accept.split(",");
      var index = 0;

      valid = false;

      while (!valid && index < allowedTypes.length) {
        var allowedType = allowedTypes[index];

        if (allowedType.startsWith("."))
          valid = file.name.endsWith(allowedType);
        else if (allowedType === "audio/*")
          valid = file.type.startsWith("audio/");
        else if (allowedType === "video/*")
          valid = file.type.startsWith("video/");
        else if (allowedType === "image/*")
          valid = file.type.startsWith("image/");
        else
          valid = file.type === allowedType;

        index++;
      }
    }
    else
      valid = true;

    return valid;
  }
}
