function WebPageCanvasComponent(element) {
  WebPageComponent.call(this, element);

  this.initialize = function () {
    this.objectString = element.dataset.Objects;
    this.objects = JSON.parse(this.objectString);

    this.geometries = new Array();

    for (var index = 0; index < this.objects.length; index++) {
      var geometryObject = this.objects[index];
      var geometry = new Geometry(GeometryType.fromText(geometryObject.Type));
      var points = geometryObject.Points;

      geometry.color = [geometryObject.Color[0] / 255, geometryObject.Color[1] / 255, geometryObject.Color[2] / 255, geometryObject.Color[3] / 255];

      for (var pointIndex = 0; pointIndex < points.length; pointIndex++) {
        var point = points[pointIndex];

        if (pointIndex == 0)
          geometry.dimension.initialize(point.X, point.Y, point.Z);
        else
          geometry.dimension.update(point.X, point.Y, point.Z);

        geometry.points.push(point.X);
        geometry.points.push(point.Y);
        geometry.points.push(point.Z);

        geometry.indices.push(pointIndex);
      }

      this.geometries.push(geometry);
    }

    this.element.setAttribute("width", this.element.clientWidth + "px");
    this.element.setAttribute("height", this.element.clientHeight + "px");
  }

  this.paint = function () {
    paint3d(this.element, this.geometries, this.rotation);
  }

  this.degreesToRadians = function (value) {
    return value / 180 * Math.PI;
  }

  this.rotate = function (x, y, z) {
    var newRotationMatrix = mat4.create();
    mat4.identity(newRotationMatrix);

    mat4.rotate(newRotationMatrix, newRotationMatrix, this.degreesToRadians(x), [1, 0, 0]);
    mat4.rotate(newRotationMatrix, newRotationMatrix, this.degreesToRadians(y), [0, 1, 0]);
    mat4.rotate(newRotationMatrix, newRotationMatrix, this.degreesToRadians(z), [0, 0, 1]);
    mat4.multiply(this.rotation, this.rotation, newRotationMatrix);
  }

  this.attachInteractivity = function () {
    var object = this;
    this.lastMouseX = null;
    this.lastMouseY = null;

    this.element.onmousedown = function handleMouseDown(event) {
      object.mouseDown = true;
      object.lastMouseX = event.clientX;
      object.lastMouseY = event.clientY;
    }

    this.element.onmouseup = function (event) {
      object.mouseDown = false;
    }

    document.addEventListener('keydown', function (event) {
      if (event.ctrlKey && event.keyCode == 37) {
        object.rotate(0, 0, -10)
      }
      else if (event.ctrlKey && event.keyCode == 39) {
        object.rotate(0, 0, 10)
      }
      else if (event.keyCode == 37) {
        object.rotate(0, -10, 0);
      }
      else if (event.keyCode == 38) {
        object.rotate(-10, 0, 0);
      }
      else if (event.keyCode == 39) {
        object.rotate(0, 10, 0);
      }
      else if (event.keyCode == 40) {
        object.rotate(10, 0, 0);
      }
    });

    this.element.onmousemove = function (event) {
      if (object.mouseDown) {
        var newX = event.clientX;
        var newY = event.clientY;

        var deltaX = newX - object.lastMouseX;
        var deltaY = newY - object.lastMouseY;

        object.rotate(deltaX, deltaY, 0);
        object.lastMouseX = newX
        object.lastMouseY = newY;
      }
    }
  }

  this.rotation = mat4.create();
  mat4.identity(this.rotation);

  this.element.focus();
  this.attachInteractivity();
  this.initialize();
  this.paint();
}

var GeometryType = new Enumeration(["Point", "Line", "Triangle"]);

function Geometry(type) {
  this.points = new Array();
  this.indices = new Array();
  this.dimension = new Dimension();

  this.type = type;
}

function Dimension() {
  this.initialize = function (x, y, z) {
    this.minX = x;
    this.maxX = x;
    this.minY = y;
    this.maxY = y;
    this.minZ = z;
    this.maxZ = z;
  }

  this.getMaximum = function () {
    return Math.max(this.size(0), this.size(1), this.size(2));
  }

  this.size = function (index) {
    if (index == 0)
      return this.maxX - this.minX;
    else if (index == 1)
      return this.maxY - this.minY;
    else if (index == 2)
      return this.maxZ - this.minZ;
  }

  this.union = function (dimension) {
    var result = new Dimension();
    result.initialize(this.minX, this.minY, this.minZ);
    result.update(this.maxX, this.maxY, this.maxZ);
    result.update(dimension.minX, dimension.minY, dimension.minZ);
    result.update(dimension.maxX, dimension.maxY, dimension.maxZ);

    return result;
  }

  this.update = function (x, y, z) {
    if (x < this.minX)
      this.minX = x;
    else if (x > this.maxX)
      this.maxX = x;

    if (y < this.minY)
      this.minY = y;
    else if (y > this.maxY)
      this.maxY = y;

    if (z < this.minZ)
      this.minZ = z;
    else if (z > this.maxZ)
      this.maxZ = z;
  }
}

function paint3d(element, geometries, rotation) {
  const canvas = document.querySelector('#glcanvas');
  const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

  if (!gl) {
    return;
  }

  const vsSource = `
        attribute vec4 aVertexPosition;
        attribute vec4 aVertexColor;
        uniform mat4 uModelViewMatrix;
        uniform mat4 uProjectionMatrix;
        varying lowp vec4 vColor;
        void main(void) {
            gl_Position = uProjectionMatrix * uModelViewMatrix * aVertexPosition;
            vColor = aVertexColor;
            gl_PointSize = 2.0;
        }
    `;

  const fsSource = `
        varying lowp vec4 vColor;
        void main(void) {
            gl_FragColor = vColor;
        }
    `;

  const shaderProgram = initShaderProgram(gl, vsSource, fsSource);

  const programInfo = {
    program: shaderProgram,
    attribLocations: {
      vertexPosition: gl.getAttribLocation(shaderProgram, 'aVertexPosition'),
      vertexColor: gl.getAttribLocation(shaderProgram, 'aVertexColor'),
    },
    uniformLocations: {
      projectionMatrix: gl.getUniformLocation(shaderProgram, 'uProjectionMatrix'),
      modelViewMatrix: gl.getUniformLocation(shaderProgram, 'uModelViewMatrix'),
    },
  };

  buffers = new Array();

  for (var index = 0; index < geometries.length; index++)
    buffers.push(initBuffers(gl, geometries[index]));

  function render(now) {
    drawScene(gl, programInfo, geometries, buffers, rotation);

    requestAnimationFrame(render);
  }

  requestAnimationFrame(render);
}

function initBuffers(gl, geometry) {
  const positionBuffer = gl.createBuffer();

  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(geometry.points), gl.STATIC_DRAW);

  const indexBuffer = gl.createBuffer();

  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
  gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(geometry.indices), gl.STATIC_DRAW);

  // Initialize color buffer.
  const colorBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, colorBuffer);

  var colors = [];

  for (var index2 = 0; index2 < geometry.points.length; index2++)
    colors = colors.concat(geometry.color);

  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(colors), gl.STATIC_DRAW);

  return {
    position: positionBuffer,
    colors: colorBuffer,
    indices: indexBuffer,
  };
}

function drawScene(gl, programInfo, geometries, buffers, rotation) {
  gl.clearColor(0.9, 0.9, 0.9, 1.0);
  gl.clearDepth(1.0);
  gl.disable(gl.DEPTH_TEST);
  gl.depthFunc(gl.LEQUAL);
  gl.depthMask(false);
  gl.enable(gl.BLEND);
  gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

  gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

  const projectionMatrix = mat4.create();
  const modelViewMatrix = mat4.create();

  mat4.perspective(projectionMatrix, 45 * Math.PI / 180, gl.canvas.clientWidth / gl.canvas.clientHeight, 0.1, 100);
  var dimension = geometries[0].dimension;

  for (var index = 1; index < geometries.length; index++)
    dimension = dimension.union(geometries[index].dimension);

  var size = [
    dimension.size(0),
    dimension.size(1),
    dimension.size(2)
  ];

  var translations = [-dimension.minX - size[0] / 2, -dimension.minY - size[1] / 2, -dimension.minZ - size[2] / 2];
  var scale = 2 / dimension.getMaximum();

  mat4.translate(modelViewMatrix, modelViewMatrix, [0, 0, -1.5]);
  mat4.multiply(modelViewMatrix, modelViewMatrix, rotation);
  mat4.scale(modelViewMatrix, modelViewMatrix, [scale, scale, scale]);
  mat4.translate(modelViewMatrix, modelViewMatrix, translations);

  for (var index = 0; index < geometries.length; index++) {
    var geometry = geometries[index];
    buffer = buffers[index];

    {
      gl.bindBuffer(gl.ARRAY_BUFFER, buffer.position);
      gl.vertexAttribPointer(programInfo.attribLocations.vertexPosition, 3, gl.FLOAT, false, 0, 0);
      gl.enableVertexAttribArray(programInfo.attribLocations.vertexPosition);
    }

    {
      gl.bindBuffer(gl.ARRAY_BUFFER, buffer.colors);
      gl.vertexAttribPointer(programInfo.attribLocations.vertexColor, 4, gl.FLOAT, false, 0, 0);
      gl.enableVertexAttribArray(programInfo.attribLocations.vertexColor);
    }

    gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, buffer.indices);
    gl.useProgram(programInfo.program);

    gl.uniformMatrix4fv(programInfo.uniformLocations.projectionMatrix, false, projectionMatrix);
    gl.uniformMatrix4fv(programInfo.uniformLocations.modelViewMatrix, false, modelViewMatrix);

    var offset = 0;
    var vertexCount = geometry.points.length / 3;

    {
      const type = gl.UNSIGNED_SHORT;

      if (geometry.type == GeometryType.Point)
        gl.drawElements(gl.POINTS, vertexCount, type, offset);
      else if (geometry.type == GeometryType.Line)
        gl.drawElements(gl.LINES, vertexCount, type, offset);
      else if (geometry.type == GeometryType.Triangle)
        gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
    }
  }
}

function initShaderProgram(gl, vsSource, fsSource) {
  const vertexShader = loadShader(gl, gl.VERTEX_SHADER, vsSource);
  const fragmentShader = loadShader(gl, gl.FRAGMENT_SHADER, fsSource);

  const shaderProgram = gl.createProgram();
  gl.attachShader(shaderProgram, vertexShader);
  gl.attachShader(shaderProgram, fragmentShader);
  gl.linkProgram(shaderProgram);

  if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
    return null;
  }

  return shaderProgram;
}

function loadShader(gl, type, source) {
  const shader = gl.createShader(type);

  gl.shaderSource(shader, source);
  gl.compileShader(shader);

  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    gl.deleteShader(shader);
    return null;
  }

  return shader;
}

interactivityRegistration.register("Canvas", function (element) { return new WebPageCanvasComponent(element); });
