class XmlMessage {
  constructor() {
  }

  toXml() {
    return "";
  }
}

class QueueSizeChangedEvent {
  constructor(size) {
    this.size = size;
  }
}

class RequestQueue {
  constructor(source) {
    this.requests = new Array();
    this.listeners = new Array();

    this.source = source;
  }

  queue(uri, method, message, body, handler, blocking = true) {
    this.requests.push(
      {
        uri: uri,
        method: method,
        message: message,
        body: body,
        handler: handler,
        blocking: blocking
      }
    );

    this.fireQueueSizeChangedEvent();

    if (this.requests.length === 1)
      this.sendNextRequest();
  }

  fireQueueSizeChangedEvent() {
    for (const listener of this.listeners)
      listener(new QueueSizeChangedEvent(this.requests.length));
  }

  sendNextRequest() {
    this.send(this.requests[0]);
  }

  async send(request) {
    const headers = {
      "Accept": "text/html,application/xhtml+xml,application/xml"
    };

    if (request.message !== null)
      headers["Content-Type"] = "application/xml; charset=\"utf-8\"";

    try {
      try {
        const response = await fetch(
          request.uri,
          {
            method: request.method,
            headers: headers,
            body: request.body ?? request.message.toXml()
          }
        );

        if (response.ok || response.status === 400)
          await request.handler(response);
        else
          this.handleErrorResponse(response);
      } catch (error) {
        this.handleError(error);
      }
    } finally {
      this.requestFinished();
    }
  }

  handleError(error) {
    this.source.handleError(error);
    console.error(error);
  }

  async handleErrorResponse(response) {
    const contentType = response.headers.get("Content-Type");

    if (contentType.slice(0, 9) === "text/html") {
      const division = document.createElement("div");
      division.innerHTML = await response.text();

      const error = new DomQuery(division).getDescendant(WithClass("Error"));
      application.toastBox.addMessage(new ToastMessage(error.firstChild, "Error"));
    }
    else if (contentType === "text/plain")
      application.toastBox.addMessage(new ToastMessage(await response.text(), "Error"));
  }

  requestFinished() {
    this.requests.shift();
    this.fireQueueSizeChangedEvent();

    if (this.requests.length >= 1)
      this.sendNextRequest();
  }

  getLast() {
    return this.requests[this.requests.length - 1];
  }

  getSize() {
    return this.requests.length;
  }
}
