function Message(element) {
  this.determineChildren = function () {
    var messageElements = new DomQuery(new DomQuery(this.element).getChild(WithClass("Messages"))).getChildren(WithClass("Message"));

    for (var index = 0; index < messageElements.length; index++)
      this.children.push(new Message(messageElements[index]));
  }

  this.getLastMessage = function () {
    if (this.children.length > 0)
      return this.children[this.children.length - 1].getLastMessage();
    else
      return this;
  }

  this.getMessageWithId = function (id) {
    if (this.id === id)
      return this;
    else {
      var result = null;
      var index = 0;

      while (result === null && index < this.children.length) {
        result = this.children[index].getMessageWithId(id);
        index++;
      }

      return result;
    }
  }

  this.getMessageList = function () {
    var messages = new Array();

    for (var index = 0; index < this.children.length; index++) {
      var message = this.children[index];
      messages.push(message);
      messages = messages.concat(message.getMessageList());
    }

    return messages;
  }

  this.attachClickHandlers = function () {
    var object = this;

    this.contentsElement.onclick = function (event) {
      object.selected.toggle();

      if (object.listener !== null)
        object.listener(object);
    }
  }

  this.element = element;
  this.id = element.dataset.Id;
  this.children = new Array();

  this.determineChildren();
  this.contentsElement = new DomQuery(this.element).getChild(WithClass("Contents"));
  this.selected = new HtmlClassSwitch(this.element, "Selected");
  this.attachClickHandlers();
}

function MessageThreadAddMessage(target, content) {
  XmlMessage.call(this);

  this.toXml = function () {
    return "<Add Target=\"" + escapeXMLAttributeValue(this.target) + "\">" + escapeXMLCharacterData(this.content) + "</Add>";
  }

  this.target = target;
  this.content = content;
}

function MessageThreadDeleteMessages() {
  XmlMessage.call(this);

  this.toXml = function () {
    var result = "<Delete>";

    for (var index = 0; index < this.ids.length; index++)
      result += "<Message Id=\"" + this.ids[index] + "\"/>";

    result += "</Delete>";
    return result;
  }

  this.add = function (id) {
    this.ids.push(id);
  }

  this.ids = new Array();
}

function MessageThreadRenderMessage(content) {
  XmlMessage.call(this);

  this.toXml = function () {
    return "<Render/>";
  }

  this.content = content;
}

function MessageThread(element) {
  WebPageComponent.call(this, element);

  this.handleRenderResponse = async function (response, messageId) {
    const newContents = document.createElement("div");
    newContents.innerHTML = await response.text();

    const newThreadElement = newContents.firstChild;
    this.threadElement.innerHTML = newThreadElement.innerHTML;

    this.determineMessages();
    this.addListener();
    this.messageSelectionChanged();

    this.scrollTo(this.getMessageWithId(messageId));
    this.progressSwitch.setStatus(false);
  }

  this.handleAddResponse = async function (response) {
    this.inputArea.value = "";

    const element = new DOMParser().parseFromString(await response.text(), "application/xml").documentElement;
    let messageId = null;

    if (element.tagName === "Message")
      messageId = element.getAttribute("Id");

    this.requestQueue.queue(
      this.uri,
      "POST",
      new MessageThreadRenderMessage(),
      null,
      (response) => this.handleRenderResponse(response, messageId)
    );
  }

  this.addMessage = function () {
    const content = this.inputArea.value;

    if (content !== "") {
      const selectedMessages = this.getSelectedMessages();
      let target = "";

      if (selectedMessages.length === 0)
        target = this.id;
      else if (selectedMessages.length === 1)
        target = selectedMessages[0].id;

      if (target !== "") {
        this.progressSwitch.setStatus(true);

        this.requestQueue.queue(
          this.uri,
          "POST",
          new MessageThreadAddMessage(target, content),
          null,
          (response) => this.handleAddResponse(response)
        );
      }
    }
  }

  this.createAddMessageHandler = function () {
    var object = this;

    return function (event) {
      object.addMessage();
    }
  }

  this.addInputArea = function () {
    this.inputArea = document.createElement("textarea");

    this.inputButton = document.createElement("input");
    this.inputButton.className = "Add";
    this.inputButton.type = "button";
    this.inputButton.onclick = this.createAddMessageHandler();

    this.inputDivision = document.createElement("div");
    this.inputDivision.className = "Input";
    this.inputDivision.appendChild(this.inputArea);
    this.inputDivision.appendChild(this.inputButton);

    this.element.appendChild(this.inputDivision);
    this.replySwitch = new HtmlClassSwitch(this.inputDivision, "Reply");
  }

  this.determineMessages = function () {
    var messageElements = new DomQuery(new DomQuery(this.element).getChild(WithClass("Contents"))).getChildren(WithClass("Message"));
    this.messages = new Array();

    for (var index = 0; index < messageElements.length; index++)
      this.messages.push(new Message(messageElements[index]));
  }

  this.getLastMessage = function () {
    if (this.messages.length > 0)
      return this.messages[this.messages.length - 1].getLastMessage();
    else
      return null;
  }

  this.getMessageWithId = function (id) {
    var result = null;
    var index = 0;

    while (result === null && index < this.messages.length) {
      result = this.messages[index].getMessageWithId(id);
      index++;
    }

    return result;
  }

  this.getMessageList = function () {
    var messages = new Array();

    for (var index = 0; index < this.messages.length; index++) {
      var message = this.messages[index];
      messages.push(message);
      messages = messages.concat(message.getMessageList());
    }

    return messages;
  }

  this.addListener = function () {
    var messageList = this.getMessageList();
    var listener = this.createListener();

    for (var index = 0; index < messageList.length; index++) {
      var message = messageList[index];
      message.listener = listener;
    }
  }

  this.scrollTo = function (message) {
    if (message !== null) {
      var messageContents = new DomQuery(message.element).getChild(WithClass("Contents"));
      var messageRectangle = messageContents.getBoundingClientRect();

      var threadRectangle = this.threadElement.getBoundingClientRect();
      var delta = messageRectangle.bottom - threadRectangle.top - threadRectangle.height + 14;

      this.threadElement.scrollTop += delta;
    }
  }

  this.handleDeleteResponse = function (response) {
    this.requestQueue.queue(
      this.uri,
      "POST",
      new MessageThreadRenderMessage(),
      null,
      (response) => this.handleRenderResponse(response, "") // TODO: Is this correct for MessageId?
    );
  }

  this.deleteMessages = function () {
    const message = new MessageThreadDeleteMessages();
    const selectedMessages = this.getSelectedMessages();

    for (let index = 0; index < selectedMessages.length; index++)
      message.add(selectedMessages[index].id);

    this.progressSwitch.setStatus(true);
    this.requestQueue.queue(
      this.uri,
      "POST",
      message,
      null,
      (response) => this.handleDeleteResponse(response)
    );
  }

  this.bind = function () {
    let query = new DomQuery(this.element);

    this.toolbarElement = query.getChild(WithClass("Toolbar"));
    this.threadElement = query.getChild(WithClass("Contents"));

    query = new DomQuery(this.toolbarElement);

    this.countElement = query.getChild(WithClass("Count"));
    this.deleteAction = query.getChild(WithClass("Delete"));
    this.confirmationTarget = query.getChild(WithClass("ConfirmationTarget"));

    query = new DomQuery(this.deleteAction);

    this.deleteButton = query.getChild(WithTagName("INPUT"));
    this.deleteButton.addEventListener(
      "click",
      (event) => {
        this.confirmationWindow.show(
          this.confirmationTarget,
          (event) => {
            this.deleteMessages();
          }
        )
      }
    );

    this.confirmationWindow = new ConfirmationWindow(query.getChild(WithClass("Confirmation")));
    this.toolbarSwitch = new HtmlClassSwitch(this.toolbarElement, "Visible");
    this.scrollTo(this.getLastMessage());

    new HtmlClassSwitch(this.threadElement, "Loaded").setStatus(true);
    this.addListener();
  }

  this.getSelectedMessages = function () {
    var messages = this.getMessageList();
    var selectedMessages = new Array();

    for (var index = 0; index < messages.length; index++) {
      var message = messages[index];

      if (message.selected.getStatus())
        selectedMessages.push(message);
    }

    return selectedMessages;
  }

  this.enableInput = function (enabled) {
    this.inputArea.disabled = !enabled;
    this.inputButton.disabled = !enabled;
  }

  this.messageSelectionChanged = function () {
    var selectedMessages = this.getSelectedMessages();

    this.toolbarSwitch.setStatus(selectedMessages.length > 0);
    this.replySwitch.setStatus(selectedMessages.length > 0);

    this.countElement.innerHTML = selectedMessages.length;
    this.enableInput(selectedMessages.length <= 1);
  }

  this.createListener = function () {
    var object = this;

    return function (message) {
      object.messageSelectionChanged();
    }
  }

  this.handleError = function (error) {
    // TODO: Implement.
  }

  this.id = element.dataset.Id;
  this.uri = element.dataset.Uri;
  this.inputArea = null;
  this.inputButton = null;
  this.requestQueue = new RequestQueue(this);
  this.messages = new Array();
  this.progressSwitch = new HtmlClassSwitch(this.element, "InProgress");
  this.toolbarSwitch = null;
  this.replySwitch = null;

  this.addInputArea();
  this.determineMessages();
}

interactivityRegistration.register("MessageThread", function (element) { return new MessageThread(element); });
