function daysInMonth(year, month) {
  return new Date(year, month + 1, 0).getDate();
}

function HTMLTable() {
  this.table = document.createElement("table");
  this.tableHeader = document.createElement("thead");
  this.table.appendChild(this.tableHeader);
  this.tableBody = document.createElement("tbody");
  this.table.appendChild(this.tableBody);

  this.addHeaderRow = function (row) {
    this.tableHeader.appendChild(row);
  }

  this.addRow = function (row) {
    this.tableBody.appendChild(row);
  }

  this.render = function () {
    return this.table;
  }
}

function HTMLTableRow() {
  this.row = document.createElement("tr");

  this.addArrayAsCells = function (array) {
    for (var index = 0; index < array.length; index++) {
      var tableData = new HTMLTableData();
      tableData.setHTML(array[index]);

      this.addTableData(tableData.render());
    }
  }

  this.addArrayAsHeaderCells = function (array) {
    for (var index = 0; index < array.length; index++) {
      var tableHeader = new HTMLTableHeader();
      tableHeader.setHTML(array[index]);

      this.addTableData(tableHeader.render());
    }
  }

  this.addTableData = function (tableData) {
    this.row.appendChild(tableData);
  }

  this.render = function () {
    return this.row;
  }
}

function HTMLTableHeader() {
  this.tableHeader = document.createElement("th");

  this.render = function () {
    return this.tableHeader;
  }

  this.setHTML = function (html) {
    this.tableHeader.innerHTML = html;
  }
}

function HTMLTableData() {
  this.tableData = document.createElement("td");

  this.appendChild = function (child) {
    this.tableData.appendChild(child);
  }

  this.render = function () {
    return this.tableData;
  }

  this.setColSpan = function (colSpan) {
    this.tableData.colSpan = colSpan;
  }

  this.setHTML = function (html) {
    this.tableData.innerHTML = html;
  }
}

function DateSelectorPage(input, dayLabels, year, month, showTime) {
  this.determineStartDay = function () {
    let day = (new Date(year, month, 1).getDay() + 6 % 7);

    if (day < 3)
      day = day + 7;

    if (day > 10)
      day = day - 7;

    return day;
  }

  this.determineEndDay = function () {
    return this.startDay + this.daysInMonth - 1;
  }

  this.dateToText = function (date) {
    var monthText = date.getMonth() + 1;
    var hourText = this.currentDate.getHours();
    var minutesText = this.currentDate.getMinutes();
    var dayText = date.getDate();

    if (monthText < 10)
      monthText = "0" + monthText;

    if (dayText < 10)
      dayText = "0" + dayText;

    if (hourText < 10)
      hourText = "0" + hourText;

    if (minutesText < 10)
      minutesText = "0" + minutesText;

    var result = date.getFullYear() + "-" + monthText + "-" + dayText

    if (this.showTime)
      result = result + " " + hourText + ":" + minutesText;

    return result;
  }

  this.daysArray = function () {
    var array = new Array(42);
    var start = this.startDay;

    var date = new Date(year, month, 1);
    date.setDate(date.getDate() - start);

    for (index = 0; index < array.length; index++) {
      array[index] = new Date(date.valueOf());
      date.setDate(date.getDate() + 1);
    }

    return array;
  }

  this.changeFocus = function (delta) {
    let previousIndex = this.focusedIndex;

    this.focusedIndex += delta;
    this.focus(previousIndex);
  }

  this.focus = function (previousIndex) {
    this.buttons[previousIndex].tabIndex = -1;

    let button = this.buttons[this.focusedIndex];
    button.tabIndex = 0;
    button.focus();
  }

  this.focusLast = function () {
    let previousIndex = this.focusedIndex;

    this.focusedIndex = this.startDay + this.daysInMonth - 1;
    this.focus(previousIndex);
  }

  this.focusFirst = function () {
    let previousIndex = this.focusedIndex;

    this.focusedIndex = this.startDay;
    this.focus(previousIndex);
  }

  this.handleDateClick = function (date) {
    return () => this.setDate(date);
  }

  this.headerRow = function () {
    let row = new HTMLTableRow();
    row.addArrayAsHeaderCells(this.dayLabels);

    return row.render();
  }

  this.isSelected = function (date) {
    let value = this.dateToText(date);
    return value.substring(0, 11) == this.input.value.substring(0, 11);
  }

  this.isToday = function (date) {
    return this.currentDate.getFullYear() == date.getFullYear() && this.currentDate.getMonth() == date.getMonth() && this.currentDate.getDate() == date.getDate();
  }

  this.render = function (table) {
    this.buttons = new Array();

    table.addHeaderRow(this.headerRow());

    let index = 0;
    let daysArray = this.daysArray();

    while (index < daysArray.length) {
      let row = new HTMLTableRow();

      for (weekDay = 0; weekDay < 7; weekDay++) {
        let label = document.createElement("span");
        label.innerText = daysArray[index].getDate();

        let button = document.createElement("button");
        button.appendChild(label);
        button.onclick = this.handleDateClick(daysArray[index]);
        button.tabIndex = -1;

        this.buttons.push(button);

        let cell = document.createElement("td");
        cell.appendChild(button);

        if (this.isToday(daysArray[index]))
          cell.classList.add("Today");

        if (this.isSelected(daysArray[index]))
          cell.classList.add("Selected");

        if (daysArray[index].getMonth() != this.month)
          cell.classList.add("OtherMonth");

        row.addTableData(cell);
        index++;
      }

      table.addRow(row.render());
    }

    return table.render();
  }

  this.setDate = function (date) {
    this.input.value = this.dateToText(date);

    this.input.dispatchEvent(new Event("input"));
    this.input.dispatchEvent(new Event("change"));
  }

  this.input = input;
  this.dayLabels = dayLabels;
  this.month = month;
  this.showTime = showTime;
  this.daysInMonth = daysInMonth(year, month);
  this.startDay = this.determineStartDay();
  this.endDay = this.determineEndDay();
  this.currentDate = new Date();
  this.focusedIndex = this.startDay;
}

function DateSelector(input, monthLabels, dayLabels, todayLabel, showTime) {
  this.changeMonth = function (index) {
    let date = this.currentDate.getDate();

    this.currentDate.setMonth(this.currentDate.getMonth() + index);

    if (this.currentDate.getDate() < date) {
      this.currentDate.setDate(0);
    }
  }

  this.create = function () {
    this.element.className = "DateSelector";
    this.element.tabIndex = "0";

    this.currentNode = this.refreshPage();
    this.element.appendChild(this.currentNode);

    this.input.oninput = (event) => {
      this.refresh();

      if (this.expanded.getStatus())
        this.setStatus(false);
    };
  }

  this.createKeyHandler = function () {
    return (event) => this.handleKey(event);
  }

  this.handleClickChangeMonthEvent = function (link, index) {
    return (event) => {
      this.changeMonth(index);
      this.refresh();

      getEvent(event).stopHandling();
    }
  }

  this.handleKey = function (event) {
    if (event.code === "ArrowLeft") {
      if (this.page.focusedIndex > this.page.startDay)
        this.page.changeFocus(-1);
      else {
        this.changeMonth(-1);
        this.refresh();
        this.page.focusLast();
      }

      event.preventDefault();
    }
    else if (event.code === "ArrowRight") {
      if (this.page.focusedIndex < this.page.endDay)
        this.page.changeFocus(1);
      else {
        this.changeMonth(1);
        this.refresh();
        this.page.focusFirst();
      }

      event.preventDefault();
    }
    else if (event.code === "ArrowUp") {
      if (this.page.focusedIndex >= this.page.startDay + 7)
        this.page.changeFocus(-7);
      else {
        let delta = this.page.startDay - (this.page.focusedIndex - 7);

        this.changeMonth(-1);
        this.refresh();
        this.page.changeFocus(this.page.daysInMonth - delta);
      }

      event.preventDefault();
    }
    else if (event.code === "ArrowDown") {
      if (this.page.focusedIndex <= this.page.endDay - 7)
        this.page.changeFocus(7);
      else {
        let delta = this.page.focusedIndex - this.page.endDay + 7;

        this.changeMonth(1);
        this.refresh();
        this.page.changeFocus(delta - 1);
      }

      event.preventDefault();
    }
    else if (event.code === "Home") {
      this.page.focusFirst();
      event.preventDefault();
    }
    else if (event.code === "End") {
      this.page.focusLast();
      event.preventDefault();
    }
  }

  this.toolbar = function () {
    let today = document.createElement("button");

    today.classList.add("Today");
    today.innerText = this.todayLabel;

    today.onclick = (event) => {
      this.currentDate = new Date();
      this.refresh();

      getEvent(event).stopHandling();
    };

    let todayTableData = new HTMLTableData();
    todayTableData.appendChild(today);
    todayTableData.setColSpan(3);

    let result = new HTMLTableRow();
    result.addTableData(new HTMLTableData().render());
    result.addTableData(new HTMLTableData().render());
    result.addTableData(todayTableData.render());
    result.addTableData(new HTMLTableData().render());
    result.addTableData(new HTMLTableData().render());

    return result.render();
  }

  this.header = function () {
    let fastBackward = document.createElement("button");
    let backward = document.createElement("button");
    let caption = document.createElement("span");
    let forward = document.createElement("button");
    let fastForward = document.createElement("button");

    fastBackward.innerHTML = "&lt;&lt;";
    fastBackward.onclick = this.handleClickChangeMonthEvent(fastBackward, -12);

    backward.innerHTML = "&lt;";
    backward.onclick = this.handleClickChangeMonthEvent(backward, -1);

    forward.innerHTML = "&gt;";
    forward.onclick = this.handleClickChangeMonthEvent(forward, 1);

    fastForward.innerHTML = "&gt;&gt;";
    fastForward.onclick = this.handleClickChangeMonthEvent(fastForward, 12);

    caption.innerHTML = this.currentDate.getFullYear() + " " + this.monthLabels[this.currentDate.getMonth()];
    caption.className = "caption";

    let result = new HTMLTableRow();
    let fastBackwardTableData = new HTMLTableData();
    let backwardTableData = new HTMLTableData();
    let captionTableData = new HTMLTableData();
    let forwardTableData = new HTMLTableData();
    let fastForwardTableData = new HTMLTableData();

    captionTableData.setColSpan(3);

    fastBackwardTableData.appendChild(fastBackward);
    backwardTableData.appendChild(backward);
    captionTableData.appendChild(caption);
    forwardTableData.appendChild(forward);
    fastForwardTableData.appendChild(fastForward);

    result.addTableData(fastBackwardTableData.render());
    result.addTableData(backwardTableData.render());
    result.addTableData(captionTableData.render());
    result.addTableData(forwardTableData.render());
    result.addTableData(fastForwardTableData.render());

    return result.render();
  }

  this.parseDate = function (dateString) {
    let date = null;

    if (dateString.length === 10) {
      let parts = dateString.split("-");

      if (parts.length === 3)
        date = new Date(parts[0], parts[1] - 1, parts[2]);
    }

    return date;
  }

  this.parseDateTime = function (dateString) {
    let date = null;

    if (dateString.length === 16 || dateString.length === 19) {
      let dateTimeParts = dateString.split(" ");

      if (dateTimeParts.length === 2) {
        let dateParts = dateTimeParts[0].split("-");
        let timeParts = dateTimeParts[1].split(":");

        if (dateParts.length === 3 && timeParts.length === 2)
          date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], timeParts[0], timeParts[1]);
        else if (dateParts.length === 3 && timeParts.length === 3)
          date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], timeParts[0], timeParts[1], timeParts[2]);
      }
    }

    return date;
  }

  this.parseValue = function (dateString) {
    if (this.showTime)
      return this.parseDateTime(dateString);
    else
      return this.parseDate(dateString)
  }

  this.refresh = function () {
    let newNode = this.refreshPage();

    this.element.replaceChild(newNode, this.currentNode);
    this.currentNode = newNode;
    this.page.focus(0);
  }

  this.refreshPage = function () {
    let table = new HTMLTable();
    table.addHeaderRow(this.toolbar());
    table.addHeaderRow(this.header());
    table.tableBody.addEventListener("keydown", this.createKeyHandler());

    this.page = new DateSelectorPage(this.input, this.dayLabels, this.currentDate.getFullYear(), this.currentDate.getMonth(), this.showTime);
    this.page.render(table);

    let result = document.createElement("div");
    result.appendChild(table.render());

    return result;
  }

  this.setStatus = function (status) {
    this.expanded.setStatus(status);

    if (status) {
      this.page.focus(0);
      this.clickOutsideListener = connectClickOutsideListener(
        this.element,
        () => this.setStatus(false)
      );
    }
    else {
      removeClickOutsideListener(this.clickOutsideListener);
      this.input.focus();
    }
  }

  this.toggle = function () {
    this.setStatus(!this.expanded.getStatus());
  }

  this.input = input;
  this.monthLabels = monthLabels;
  this.dayLabels = dayLabels;
  this.todayLabel = todayLabel;
  this.showTime = showTime;
  this.element = document.createElement("div");
  this.expanded = new HtmlClassSwitch(this.element, "Expanded");

  if (this.input.value.length > 0) {
    let value = this.parseValue(input.value);

    if (value !== null)
      this.currentDate = value;
    else
      this.currentDate = new Date();
  }
  else
    this.currentDate = new Date();

  this.create();
}

class DateField extends DefaultFormField {
  constructor(element, showTime) {
    super(element);

    this.showTime = showTime;

    if (this.mode === ControlMode.edit) {
      this.determineElements();
      this.initializeLabels();
      this.createSelector();
      this.attachEventHandlers();
    }
  }

  attachEventHandlers() {
    this.button.addEventListener(
      "click",
      (event) => this.selector.toggle()
    );

    this.input.addEventListener("keyup", this.createKeyHandler());
    this.addValueChangedHandler(this.input);

    this.input.addEventListener(
      "blur",
      (event) => {
        if (!this.selector.expanded.getStatus() && event.relatedTarget !== this.button && event.relatedTarget !== null)
          this.validate();
      }
    );
  }

  checkInput(problemTarget) {
    super.checkInput(problemTarget);

    if (this.value !== null) {
      if (this.showTime && !(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(this.value) && this.isValidDateTime(this.value)))
        problemTarget.addProblem(this, this.element.dataset.InvalidValueMessage);
      else if (!this.showTime && !(/^\d{4}-\d{2}-\d{2}$/.test(this.value) && this.isValidDate(this.value)))
        problemTarget.addProblem(this, this.element.dataset.InvalidValueMessage);
    }
  }

  isValidDate(value) {
    const [year, month, day] = value.split("-").map(Number);
    const date = new Date(year, month - 1, day);

    return (year >= 1 && month >= 1 && month <= 12 && day >= 1 && day <= 31)
      && (date.getFullYear() === year && date.getMonth() + 1 === month && date.getDate() === day);
  }

  isValidDateTime(value) {
    const [datePart, timePart] = value.split(" ");
    const [hour, minute] = timePart.split(":").map(Number);

    return this.isValidDate(datePart)
      && (hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59)
  }

  createKeyHandler() {
    return (event) => { return this.handleKey(event) };
  }

  createSelector() {
    this.selector = new DateSelector(this.input, this.monthLabels, this.dayLabels, this.todayLabel, this.showTime);
    this.element.appendChild(this.selector.element);
  }

  determineElements() {
    let query = new DomQuery(this.element);

    this.button = query.getChild(WithTagName("BUTTON"));
    this.button.tabIndex = -1;

    let label = query.getChild(WithClass("Labels"));
    this.labels = new DomQuery(label).getChildren(WithClass("Label"));

    this.input = query.getChild(WithTagName("INPUT"));
  }

  handleKey(event) {
    if (event.ctrlKey && event.code === "Space")
      this.selector.toggle();
  }

  initializeLabels() {
    this.monthLabels = new Array();
    this.dayLabels = new Array();

    let labelIndex = 0;

    for (let index = 1; index <= 12; index++) {
      this.monthLabels.push(this.labels[labelIndex].innerHTML);
      labelIndex++;
    }

    for (let index = 1; index <= 7; index++) {
      this.dayLabels.push(this.labels[labelIndex].innerHTML);
      labelIndex++;
    }

    this.todayLabel = this.labels[labelIndex].innerHTML;
  }

  removeClickOutsideListener() {
    let htmlElement = document.getElementsByTagName("html")[0];
    htmlElement.removeEventListener("click", this.clickOutsideListener, true);
  }
}

interactivityRegistration.register("DateField", function (element) { return new DateField(element, false); });
interactivityRegistration.register("DateTimeField", function (element) { return new DateField(element, true); });
