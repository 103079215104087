class UuidField extends DefaultFormField {
  constructor(element) {
    super(element);

    this.determineElements();
    this.attachHandlers();
  }

  attachHandlers() {
    if (this.input !== null) {
      this.addValueChangedHandler(this.input);
      this.addBlurHandler(this.input);
    }
  }

  checkInput(problemTarget) {
    super.checkInput(problemTarget);

    const value = this.getValue();

    if (value !== null && !/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(value))
      problemTarget.addProblem(this, this.element.dataset.InvalidValueMessage);
  }

  determineElements() {
    this.input = this.element;
  }
}

interactivityRegistration.register("UuidField", function (element) { return new UuidField(element); });
