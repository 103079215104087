function newXmlRequest() {
  var result = null;

  if (typeof XMLHttpRequest != "undefined") {
    result = new XMLHttpRequest();
  }
  else if (typeof window.ActiveXObject != "undefined") {
    try {
      result = new ActiveXObject("Msxml2.XMLHTTP.4.0");
    }
    catch (e) {
      try {
        result = new ActiveXObject("Msxml2.XMLHTTP");
      }
      catch (e) {
        try {
          result = new ActiveXObject("Microsoft.XMLHTTP");
        }
        catch (e) {
          result = null;
        }
      }
    }
  }

  return result;
}

function escapeXMLCharacterData(xml) {
  var result = "";

  for (var i = 0; i < xml.length; i++) {
    var c = xml[i];

    if (c == ">")
      result += "&gt;";
    else if (c == "<")
      result += "&lt;";
    else if (c == "&")
      result += "&amp;";
    else
      result += c;
  }

  return result;
}

function escapeXMLAttributeValue(xml) {
  var result = "";

  for (var i = 0; i < xml.length; i++) {
    var c = xml[i];

    if (c == ">")
      result += "&gt;";
    else if (c == "<")
      result += "&lt;";
    else if (c == "&")
      result += "&amp;";
    else if (c == "\"")
      result += "&quot;";
    else if (c == "'")
      result += "&apos;";
    else
      result += c;
  }

  return result;
}

function getCurrentScript() {
  var scriptElements = document.getElementsByTagName("script");
  return scriptElements[scriptElements.length - 1];
}

function openUrl(event, url) {
  event = getEvent(event);

  if (!nodeIsInteractive(event.getSource())) {
    if (event.ctrlKey)
      window.open(url);
    else
      application.pageHandler.navigate(url);
  }
}

function nodeHasClass(node, className) {
  var result = false;

  if (typeof node.className == "string") {
    var nodeClasses = node.className.split(" ");
    var i = 0;

    while (!result && i < nodeClasses.length)
      if (nodeClasses[i] == className)
        result = true;
      else
        i++;
  }

  return result;
}

function addNodeClass(node, className) {
  setNodeClassEnabled(node, className, true);
}

function removeNodeClass(node, className) {
  setNodeClassEnabled(node, className, false);
}

function setNodeClassEnabled(node, className, enabled) {
  new HtmlClassSwitch(node, className).setStatus(enabled);
}

function nodeIsInteractive(node) {
  return node.nodeName.toLowerCase() == "input";
}

function setHot(node, event, hot) {
  var source = getEvent(event).getSource();

  if (!nodeIsInteractive(source))
    setNodeClassEnabled(node, "hot", hot);
}

function isLink(source) {
  return source.nodeName.toLowerCase() == "a";
}

function isFormElement(source) {
  var name = source.nodeName.toLowerCase();

  return name == "select" || name == "option" || name == "input" || name == "textarea" || name == "button" || name == "label";
}

function changeNodeDisplay(node, display) {
  node.style.display = display;
}

function hideNode(node) {
  changeNodeDisplay(node, "none");
}

function showNode(node) {
  changeNodeDisplay(node, "block");
}

function showImageAttributes(node, altText) {
  var child = node.childNodes[1];

  node.title = altText;

  if (child) {
    showNode(child);
  }
}

function hideImageAttributes(node) {
  var child = node.childNodes[1];

  if (child)
    hideNode(child);
}

function booleanToXML(value) {
  if (value)
    return "true";
  else
    return "false";
}

function expand(node) {
  var itemNode = node.parentNode;
  var result = nodeHasClass(itemNode, "collapsed");

  if (result) {
    removeNodeClass(itemNode, "collapsed");
    addNodeClass(itemNode, "expanded");
  }

  return result;
}

function collapse(node) {
  var itemNode = node.parentNode;
  var result = nodeHasClass(itemNode, "expanded");

  if (result) {
    removeNodeClass(itemNode, "expanded");
    addNodeClass(itemNode, "collapsed");
  }

  return result;
}

function expandOrCollapse(node) {
  return expand(node) || collapse(node);
}

function shouldHandleMouseClick(parent, source) {
  var result = true;

  while (result && source != parent) {
    result = !isLink(source) && !isFormElement(source);
    source = source.parentNode;
  }

  return result;
}

function expandOrCollapseOnClick(node, event) {
  var source = getEvent(event).getSource();

  return shouldHandleMouseClick(node, source) && expandOrCollapse(node);
}

class InteractivityRegistrationEntry {
  constructor(class_, constructor) {
    this._class = class_;
    this._constructor = constructor;
  }

  get class_() {
    return this._class;
  }

  get constructor_() {
    return this._constructor;
  }
}

class InteractivityRegistration {
  constructor() {
    this.entries = [];
  }

  attach(element) {
    const query = new DomQuery(element);
    const entries = this.entries;
    const components = new Array();

    query.traversePostOrder(
      function (element) {
        if (element.component === undefined) {
          for (const entry of entries) {
            if (nodeHasClass(element, entry.class_)) {
              const component = entry.constructor_(element);

              if (component !== null) {
                element.component = component;
                components.push(element.component);
              }
            }
          }
        }
      }
    );

    this.updateHierarchy(components);

    for (const component of components)
      component.bind();
  }

  detach(element) {
    const parent = this.getParentComponent(element);

    if (parent !== null) {
      const components = parent.childComponents.filter(child => element.contains(child.element));

      for (const component of components)
        this.detachComponent(component);

      parent.childComponents = parent.childComponents.filter(child => !element.contains(child.element));
    }
    else if (element.component !== undefined)
      this.detachComponent(element.component);
  }

  detachComponent(component) {
    for (const child of component.childComponents)
      this.detachComponent(child);

    component.release();
  }

  getParentComponent(element) {
    let parent = element.parentNode;
    let result = null;

    while (result == null && parent !== null) {
      if (parent.component !== undefined)
        result = parent.component;
      else
        parent = parent.parentNode;
    }

    return result;
  }

  getComponents(element) {
    const parent = this.getParentComponent(element);
    return parent.childComponents.filter(child => element.contains(child.element));
  }

  register(class_, constructor_) {
    this.entries.push(new InteractivityRegistrationEntry(class_, constructor_));
  }

  updateHierarchy(components) {
    for (const component of components) {
      const parent = this.getParentComponent(component.element);

      if (parent !== null) {
        parent.childComponents.push(component);
        component.parentComponent = parent;
      }
    }
  }
}

var interactivityRegistration = new InteractivityRegistration();
