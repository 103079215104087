class FloatingPointIntervalField extends WebPageComponentClass {
  constructor(element) {
    super(element);

    this.determineElements();
  }

  addEventListener(event, handler) {
    this.input.addEventListener(event, handler);
  };

  determineElements() {
    const query = new DomQuery(this.element);

    this.low = query.getChild(WithClass("Low"));
    this.low.addEventListener(
      "change",
      () => { this.input.value = this.determineValue(); }
    );

    this.high = query.getChild(WithClass("High"));
    this.high.addEventListener(
      "change",
      () => { this.input.value = this.determineValue(); }
    );

    this.input = query.getChild(WithClass("Interval"));
    this.input.value = this.determineValue();
  }

  determineValue() {
    return this.low.value + "," + this.high.value;
  }

  focus() {
    this.low.focus();
  }

  getValue() {
    return this.input.value;
  }

  setValue(value) {
    if (value !== this.input.value) {
      this.input.value = value;
      this.input.dispatchEvent(new Event("change"));
    }
  }

  get name() {
    if (this.input !== null)
      return this.input.name;
    else
      return null;
  }
}

interactivityRegistration.register("FloatingPointIntervalField", (element) => { return new FloatingPointIntervalField(element); });
