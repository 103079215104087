class ConfirmationWindowComponent extends WebPageComponentClass {
  constructor(element, message) {
    super(element);

    this.element.classList.add("ConfirmationWindowComponent");

    this.wrapper = document.createElement("div");
    this.wrapper.classList.add("Wrapper");

    // TODO: Add a proper label.
    this.message = document.createElement("span");
    this.message.classList.add("Message");
    this.message.textContent = message;

    this.submit = document.createElement("button");
    this.submit.classList.add("Submit");
    this.submit.textContent = "Yes";

    this.cancel = document.createElement("button");
    this.cancel.classList.add("Cancel");
    this.cancel.textContent = "No";

    this.wrapper.appendChild(this.message);
    this.wrapper.appendChild(this.submit);
    this.wrapper.appendChild(this.cancel);

    this.element.appendChild(this.wrapper);
  }

  show(onSuccess) {
    this.element.classList.add("Expanded");

    this.submit.addEventListener(
      "click",
      () => {
        onSuccess();
        this.element.classList.remove("Expanded");
      }
    );

    this.cancel.addEventListener("click", () => this.element.classList.remove("Expanded"));
  }
}

interactivityRegistration.register("ConfirmationWindowComponent", (element) => { return new ConfirmationWindowComponent(element); });
