class TableOfContents extends WebPageComponentClass {
  constructor(element) {
    super(element);

    this.items = new Array();

    this.target = document.querySelector("main");
    this.viewPort = this.element.childNodes[1];

    this.determineElements();
    this.attachHandlers();
    this.handleScroll();
  }

  attachHandlers() {
    this.target.addEventListener(
      "scroll",
      (event) => {
        this.handleScroll();
      }
    );
  }

  determineElements() {
    this.nodes = new DomQuery(this.element).getDescendants(WithTagName("LI"));
    const items = new DomQuery(this.element).getDescendants(WithTagName("A"));

    for (const item of items)
      this.items.push(new TableOfContentsItem(item.parentElement.parentElement, item, this.target));

    this.items.sort(function (left, right) {
      if (left.reference.offsetTop > right.reference.offsetTop)
        return -1
      else if (left.reference.offsetTop < right.reference.offsetTop)
        return 1
      else
        return 0;
    })
  }

  handleScroll() {
    let result = false;
    let active = null;

    for (const item of this.items) {
      if (item.isActive() && !result) {
        result = true;
        item.element.classList.add("Active");

        active = item;
      }
      else
        item.element.classList.remove("Active");
    }

    if (active !== null) {
      if (active.item.offsetTop < this.viewPort.scrollTop || active.item.offsetTop + active.item.offsetHeight > this.viewPort.scrollTop + this.viewPort.offsetHeight)
        this.viewPort.scrollTop = active.item.offsetTop;
    }
  }
}

class TableOfContentsItem extends WebPageComponentClass {
  constructor(element, item, target) {
    super(element);

    this.item = item;
    this.target = target;
    this.reference = document.getElementById(decodeURIComponent(this.item.getAttribute("href").substring(1)));
  }

  isActive() {
    return (this.reference !== null) && (this.target.scrollTop >= this.reference.offsetTop);
  }
}

interactivityRegistration.register("TableOfContents", function (element) { return new TableOfContents(element); });
