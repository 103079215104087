class WebSiteStore {
  constructor() {
    this.databaseName = "WebSiteStore";
    this.storeName = "Settings";
    this.database = this.initialize();
  }

  initialize() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(this.databaseName, 1);

      request.onupgradeneeded = (event) => {
        const database = event.target.result;

        if (!database.objectStoreNames.contains(this.storeName)) {
          database.createObjectStore(this.storeName, { keyPath: "key" });
        }
      };

      request.onsuccess = (event) => {
        resolve(event.target.result);
      };

      request.onerror = (event) => {
        reject(event.target.error);
      };
    });
  }

  async set(key, value) {
    const database = await this.database;

    return new Promise((resolve, reject) => {
      const transaction = database.transaction([this.storeName], "readwrite");
      const store = transaction.objectStore(this.storeName);
      const request = store.put({ key: key, value: value });

      request.onsuccess = () => resolve(true);
      request.onerror = (event) => reject(event.target.error);
    });
  }

  async get(key) {
    const database = await this.database;

    return new Promise((resolve, reject) => {
      const transaction = database.transaction([this.storeName], "readonly");
      const store = transaction.objectStore(this.storeName);
      const request = store.get(key);

      request.onsuccess = (event) => {
        const result = event.target.result;
        resolve(result ? result.value : undefined);
      };

      request.onerror = (event) => reject(event.target.error);
    });
  }

  async delete(key) {
    const database = await this.database;

    return new Promise((resolve, reject) => {
      const transaction = database.transaction([this.storeName], "readwrite");
      const store = transaction.objectStore(this.storeName);
      const request = store.delete(key);

      request.onsuccess = () => resolve(true);
      request.onerror = (event) => reject(event.target.error);
    });
  }
}
