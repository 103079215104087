class BreadcrumbsComponent extends WebPageComponentClass {
  constructor(element) {
    super(element);
  }

  bind() {
    this.menu = this.element.childNodes[0].component;
  }

  handleEvent(event) {
    if (event instanceof DataChangedEvent)
      this.invalidate();
  }

  async validate() {
    this.reload();
  }

  reload() {
    const uri = window.location.href;

    fetch(
      this.uri,
      {
        method: "POST",
        headers: {
          "Accept": "text/html",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          Uri: uri
        })
      }
    )
      .then((response) => this.reloadFromResponse(response));
  }

  async reloadFromResponse(response) {
    const dummyElement = document.createElement("div");
    dummyElement.innerHTML = await response.text();

    interactivityRegistration.detach(this.menu.element);
    this.element.replaceChild(dummyElement.childNodes[0].childNodes[0], this.menu.element);

    const newMenu = this.element.childNodes[0];
    interactivityRegistration.attach(newMenu);
    this.menu = newMenu.component;
  }

  get uri() {
    return this.element.dataset.Uri;
  }
}

interactivityRegistration.register("Breadcrumbs", function (element) { return new BreadcrumbsComponent(element); });
