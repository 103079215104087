class PasswordField extends DefaultFormField {
  constructor(element) {
    super(element);

    this.determineElements();
    this.attachHandlers();
  }

  attachHandlers() {
    if (this.input !== null) {
      this.addValueChangedHandler(this.input);
      this.addBlurHandler(this.input);
    }
  }

  determineElements() {
    this.input = new DomQuery(this.element).getChild(WithTagName("INPUT"));
  }
}

interactivityRegistration.register("PasswordField", function (element) { return new PasswordField(element); });
