function TypeMode(element) {
  this.determineElements = function () {
    this.value = this.element.dataset.Value;

    this.selected = new HtmlClassSwitch(this.element, "Selected");
    this.selected.setStatus(false);
  }

  this.element = element;
  this.selected = null;

  this.determineElements();
}

function ChoiceRow(index) {
  this.determineElements = function () {
    this.element = document.createElement("div");
    this.element.className = "Row";
    this.element.setAttribute("data--index", this.index);

    this.removeButton = document.createElement("div");
    this.removeButton.className = "Remove";

    this.valueField = document.createElement("input");
    this.valueField.name = "Value";
    this.valueField.className = "Value";

    this.textField = document.createElement("input");
    this.textField.name = "Text";
    this.textField.className = "Text";

    this.element.appendChild(this.removeButton);
    this.element.appendChild(this.valueField);
    this.element.appendChild(this.textField);
  }

  this.index = index;
  this.element = null;
  this.removeButton = null;
  this.valueField = null;
  this.textField = null;

  this.determineElements();
}

function ChoiceFactory(field) {
  this.determineElements = function () {
    var query = new DomQuery(this.field.element);

    this.element = query.getChild(WithClass("Choices"));

    this.expanded = new HtmlClassSwitch(this.element, "Expanded");
    this.expanded.setStatus(false);

    query = new DomQuery(this.element);

    this.addButton = query.getDescendant(WithClass("Add"));

    var rows = query.getChildren(WithClass("Row"));

    for (var i = 0; i < rows.length; i++) {
      var row = rows[i];
      var newRow = new ChoiceRow(row.dataset.Index);
      var object = this;

      this.count++;
      query = new DomQuery(row);

      newRow.removeButton = query.getChild(WithClass("Remove"));
      newRow.removeButton.onclick = this.createRemoveButtonClickedHandler(newRow);

      newRow.valueField = query.getChild(WithClass("Value"));
      newRow.valueField.oninput = function (event) { object.updateTextArea(); };

      newRow.textField = query.getChild(WithClass("Text"));
      newRow.textField.oninput = function (event) { object.updateTextArea(); };

      this.rows.push(newRow);
    }
  }

  this.updateTextArea = function () {
    this.textArea.value = "<Choice Optional=\"" + field.optionalCheckBox.checked + "\">";

    for (var i = 0; i < this.rows.length; i++) {
      var row = this.rows[i];

      this.textArea.value += "<Option Value=\"" + row.valueField.value + "\">" + row.textField.value + "</Option>";
    }

    this.textArea.value += "</Choice>";
  }

  this.addChoiceRow = function () {
    var object = this;
    var row = new ChoiceRow(this.count++);

    row.removeButton.onclick = function (event) { object.removeChoiceRow(row); };
    row.valueField.oninput = function (event) { object.updateTextArea(); };
    row.textField.oninput = function (event) { object.updateTextArea(); };

    this.element.appendChild(row.element);
    this.rows.push(row);
  }

  this.createRemoveButtonClickedHandler = function (row) {
    var object = this;

    return function () {
      object.removeChoiceRow(row);
    };
  }

  this.removeChoiceRow = function (row) {
    var query = new DomQuery(this.element);
    var rows = query.getChildren(WithClass("Row"));

    for (var i = 0; i < rows.length; i++) {
      var rowNode = rows[i];

      if (rowNode.dataset.Index == row.index) {
        this.element.removeChild(rowNode);
        this.rows.splice(i, 1);
      }
    }

    this.updateTextArea();
  }

  this.field = field;
  this.textArea = this.field.textArea;
  this.element = null;
  this.expanded = null;
  this.addButton = null;
  this.rows = [];
  this.count = 0;

  this.determineElements();
}

function TypeField(element) {
  WebPageComponent.call(this, element);

  this.determineElements = function () {
    var query = new DomQuery(this.element);

    var modes = query.getChild(WithClass("Modes"));

    for (var i = 0; i < modes.childNodes.length; i++) {
      this.modes.push(new TypeMode(modes.childNodes[i]));
    }

    this.textArea = query.getDescendant(WithTagName("TEXTAREA"));
    this.readOnly = this.textArea.readOnly;

    this.textLength = query.getDescendant(WithClass("TextLength"))
    this.optional = query.getDescendant(WithClass("Optional"));

    this.showTextLength = new HtmlClassSwitch(this.textLength, "Expanded");
    this.showTextLength.setStatus(false);

    query = new DomQuery(this.textLength);

    this.textLengthLow = query.getDescendant(WithClass("Low"));
    this.textLengthHigh = query.getDescendant(WithClass("High"));

    query = new DomQuery(this.optional);

    this.showOptional = new HtmlClassSwitch(this.optional, "Expanded");
    this.showOptional.setStatus(false);

    this.optionalCheckBox = query.getDescendant(WithTagName("INPUT"));

    this.choiceFactory = new ChoiceFactory(this);
    this.structuredText = this.textArea.value;

    if (this.structuredText !== "") {
      if (this.structuredText.slice(0, 23) == "<Text Type=\"SingleLine\"")
        this.mode = "Text"
      else if (this.structuredText.slice(0, 7) == "<Choice")
        this.mode = "Choice"
      else if (this.structuredText.slice(0, 9) == "<Document")
        this.mode = "Document"
      else
        this.mode = "TextArea";
    }

    this.updateMode();
  }

  this.updateMode = function () {
    if (this.mode == "Text")
      this.textArea.value = "<Text Type=\"SingleLine\" Interval=\"" + this.textLengthLow.value + "," + this.textLengthHigh.value + "\"/>"
    else if (this.mode == "TextArea")
      this.textArea.value = "<Text Interval=\"" + this.textLengthLow.value + "," + this.textLengthHigh.value + "\"/>"
    else if (this.mode == "Document") {
      this.textArea.value = "<Document Optional=\"" + this.optionalCheckBox.checked + "\"/>"
    }
    else
      this.choiceFactory.updateTextArea();

    this.showTextLength.setStatus(this.mode == "Text" || this.mode == "TextArea");
    this.showOptional.setStatus(this.mode == "Document" || this.mode == "Choice");
    this.choiceFactory.expanded.setStatus(this.mode == "Choice" && !this.readOnly);

    for (var i = 0; i < this.modes.length; i++) {
      var mode = this.modes[i];

      mode.selected.setStatus(this.mode == mode.value);
    }
  }

  this.toggleMode = function (value) {
    this.mode = value;
    this.updateMode();
  }

  this.createModeClickedHandler = function (mode) {
    var object = this;

    return function () {
      object.toggleMode(mode.value);
    };
  }

  this.createTextLengthChangedHandler = function () {
    var object = this;

    return function () {
      object.updateMode();
    };
  }

  this.createOptionalChangedHandler = function () {
    var object = this;

    return function () {
      object.updateMode();
    };
  }

  this.createAddButtonClickedHandler = function () {
    var object = this;

    return function () {
      object.choiceFactory.addChoiceRow();
    };
  }

  this.attachEventHandlers = function () {
    if (!this.readOnly) {
      for (var i = 0; i < this.modes.length; i++) {
        var mode = this.modes[i];

        mode.element.onclick = this.createModeClickedHandler(mode);
      }

      this.textLengthLow.oninput = this.createTextLengthChangedHandler();
      this.textLengthHigh.oninput = this.createTextLengthChangedHandler();
      this.optionalCheckBox.onclick = this.createOptionalChangedHandler();
      this.choiceFactory.addButton.onclick = this.createAddButtonClickedHandler();
    }
  }

  this.textArea = null;
  this.textLength = null;
  this.optional = null;
  this.readOnly = true;
  this.structuredText = null;
  this.choiceFactory = null;
  this.modes = [];
  this.mode = "Text";

  this.determineElements();
  this.attachEventHandlers();
}

function TypeComponent(element) {
  WebPageComponent.call(this, element);

  this.attachFieldInteraction = function () {
    var object = this;
    var fields = new DomQuery(this.section).getDescendants(WithTagName("INPUT"));

    for (var index = 0; index < fields.length; index++)
      fields[index].onchange = function () { object.writeFields(); };
  }

  this.attachInteractivity = function () {
    var object = this;

    this.baseTypeSelector.input.onchange = function (event) {
      object.updateForm();

      if (object.type !== null)
        object.writeFields();
    };

    this.value.onchange = function (event) {
      object.type = null;

      object.determineBaseType();
      object.parseValue();
      object.readFields();
    };
  }

  this.createFormForType = function (type) {
    var element = new DomQuery(this.forms).getChild(WithClass(type + "Form"));
    return element.innerHTML;
  }

  this.determineBaseType = function () {
    var value;

    if (this.value.value.substring(0, 10) === "Set(Record")
      value = "RecordSet"
    else
      value = this.value.value.split("(")[0];

    this.baseTypeSelector.setValue(value);
  }

  this.determineElements = function () {
    this.baseTypeSelector = this.getDescendant(ComponentWithName("BaseType"));
    this.value = new DomQuery(this.element).getChild(WithTagName("INPUT"));
    this.forms = new DomQuery(this.element).getChild(WithClass("Forms"));
    this.form = new DomQuery(this.element).getChild(WithClass("Properties"));
  };

  this.getFormValue = function (name) {
    var value = this.section.component.getDescendant(new ComponentWithName(name)).getValue();

    if (value === "")
      value = null;

    return value;
  }

  this.getLabels = function () {
    return this.labels;
  }

  this.parseValue = function () {
    this.type = this.parser.parse(this.baseTypeSelector.getValue(), this.value.value);
  }

  this.readFields = function () {
    if (this.type.baseType === "Text") {
      if (this.type.maximumLength !== null)
        this.setFormValue("Properties/MaximumLength", this.type.maximumLength);

      if (this.type.lines !== null)
        this.setFormValue("Properties/Height", this.type.lines);
    }
    else if (this.type.baseType === "Decimal") {
      if (this.type.digits !== null)
        this.setFormValue("Properties/Digits", this.type.digits);

      if (this.type.decimalPlaces !== null)
        this.setFormValue("Properties/DecimalPlaces", this.type.decimalPlaces);
    }
    else if (this.type.baseType === "Enumeration") {
      var target = this.section.component.getDescendant(new ComponentWithClass(EnumerationTypeBuilder));
      target.setValue(this.type);
    }
    else if (this.type.baseType === "RecordSet") {
      var target = this.section.component.getDescendant(new ComponentWithClass(RecordTypeBuilder));
      target.setValue(this.type);
    }
    else if (this.type.baseType === "Data")
      this.setFormValue("Properties/DataSource", this.type.identifier);
  }

  this.setFormValue = function (name, value) {
    this.section.component.getDescendant(new ComponentWithName(name)).setValue(value);
  }

  this.setLabels = function (labels) {
    this.labels = labels;
  }

  this.setValue = function (value) {
    this.value.value = value;
  }

  this.updateForm = function () {
    interactivityRegistration.detach(this.form);
    this.form.innerHTML = "";
    this.form.innerHTML = this.createFormForType(this.baseTypeSelector.getValue());

    interactivityRegistration.attach(this.form);

    this.section = new DomQuery(this.form).getChild(WithClass("FormSection"));
    this.attachFieldInteraction();
  }

  this.writeFields = function () {
    this.type = new Type(this.baseTypeSelector.getValue());

    if (this.type.baseType === "Text") {
      this.type.maximumLength = this.getFormValue("Properties/MaximumLength");
      this.type.lines = this.getFormValue("Properties/Height");
    }
    else if (this.type.baseType === "Decimal") {
      this.type.digits = this.getFormValue("Properties/Digits");
      this.type.decimalPlaces = this.getFormValue("Properties/DecimalPlaces");
    }
    else if (this.type.baseType === "Enumeration") {
      var target = this.section.component.getDescendant(new ComponentWithClass(EnumerationTypeBuilder));
      target.updateValue();
      this.type = target.type;
    }
    else if (this.type.baseType === "RecordSet") {
      var target = this.section.component.getDescendant(new ComponentWithClass(RecordTypeBuilder));
      target.updateValue();
      this.type = target.type;
    }
    else if (this.type.baseType === "Data")
      this.type.identifier = this.getFormValue("Properties/DataSource");

    this.setValue(this.writer.write(this.type));
  }

  this.type = null;
  this.labels = new Array();

  this.writer = new TypeWriter();
  this.parser = new TypeParser();

  this.determineElements();
  this.determineBaseType();

  this.updateForm();

  this.attachInteractivity();
  this.parseValue();
  this.readFields();
}

function Type(baseType) {
  this.baseType = baseType;
}

function TypeParser() {
  this.parse = function (baseType, value) {
    var type = new Type(baseType);

    if (baseType === "Text")
      this.parseTextType(value, type);
    else if (baseType === "Decimal")
      this.parseDecimalType(value, type);
    else if (baseType === "Enumeration")
      this.parseEnumerationType(value, type);
    else if (baseType === "RecordSet")
      this.parseRecordSetType(value, type);
    else if (baseType === "Data")
      this.parseDataType(value, type);

    return type;
  }

  this.parseDataType = function (value, type) {
    var current = { "value": value, "remainder": "" };

    if (this.parseDelimiter(current, "Data(")) {
      this.readUntilAndParseDelimiter(current, ")");
      type.identifier = current.remainder;
    }
  }

  this.parseDelimiter = function (value, delimiter) {
    parts = value.value.split(delimiter, 2);
    var result = (parts.length === 2 && parts[0].length === 0)

    if (result) {
      value.value = parts[1];
      value.remainder = parts[0];
    }

    return result;
  }

  this.parseDecimalType = function (value, type) {
    var current = { "value": value, "remainder": "" };

    type.digits = null;
    type.decimalPlaces = null;

    if (this.parseDelimiter(current, "Decimal(")) {
      this.readUntilAndParseDelimiter(current, ")");
      var values = current.remainder.split(",");

      if (values.length === 2) {
        type.digits = parseInt(values[0]);
        type.decimalPlaces = parseInt(values[1]);
      }
    }
  }

  this.parseEnumerationType = function (value, type) {
    if (value.substring(0, 11) == "Enumeration" && value.length > 11)
      type.options = value.substring(12, value.length - 1).split(",");
    else
      type.options = new Array();
  }

  this.parseRecordSetType = function (value, type) {
    type.members = new Array();

    if (value.substring(0, 10) == "Set(Record" && value.length > 10) {
      var members = value.substring(11, value.length - 2).match(/[A-z0-9_]*:[A-z]*(\([A-z0-9:,]*\))?/g);

      for (var member of members) {
        type.members.push({
          name: member.split(":", 2)[0],
          type: member.split(":", 2)[1]
        });
      }
    }
  }

  this.parseTextType = function (value, type) {
    var current = { "value": value, "remainder": "" };

    type.maximumLength = null;
    type.lines = null;

    if (this.parseDelimiter(current, "Text(")) {
      if (this.parseDelimiter(current, "[")) {
        this.readUntilAndParseDelimiter(current, "]");
        var interval = current.remainder.split(",");

        if (interval.length === 1)
          type.maximumLength = parseInt(interval[0]);
        else if (interval[1].length > 0)
          type.maximumLength = parseInt(interval[1]);

        this.readUntilAndParseDelimiter(current, ",");
      }

      if (this.parseDelimiter(current, "Lines:")) {
        this.readUntilAndParseDelimiter(current, ")");
        type.lines = parseInt(current.remainder);
      }
    }
  }

  this.readUntilAndParseDelimiter = function (value, delimiter) {
    parts = value.value.split(delimiter, 2);

    var result = parts.length === 2;

    if (result) {
      value.value = parts[1];
      value.remainder = parts[0];
    }

    return result;
  }
}

function TypeWriter() {
  this.write = function (type) {
    var value = new String(type.baseType);

    if (type.baseType === "Text")
      value = this.writeTextType(type);
    else if (type.baseType === "Decimal")
      value = this.writeDecimalType(type);
    else if (type.baseType === "Enumeration")
      value = this.writeEnumerationType(type);
    else if (type.baseType === "RecordSet")
      value = this.writeRecordSetType(type);
    else if (type.baseType === "Data")
      value = this.writeDataType(type);

    return value;
  }

  this.writeDataType = function (type) {
    var result = "Data";

    result += "(";
    result += type.identifier;
    result += ")";

    return result;
  }

  this.writeDecimalType = function (type) {
    var result = "Decimal";

    if (type.digits !== null && type.decimalPlaces !== null) {
      result += "(";
      result += type.digits;
      result += ",";
      result += type.decimalPlaces;
      result += ")";
    }

    return result;
  }

  this.writeEnumerationType = function (type) {
    var result = "Enumeration(";

    for (var index = 0; index < type.options.length - 1; index++)
      result += type.options[index] + ",";

    if (type.options.length > 0)
      result += type.options[type.options.length - 1];

    result += ")";
    return result;
  }

  this.writeRecordSetType = function (type) {
    var result = "Set(Record(";

    if (type.members.length > 0) {
      result += this.writeRecordMember(type.members[0]);

      for (var index = 1; index < type.members.length; index++) {
        result += ",";
        result += this.writeRecordMember(type.members[index]);
      }
    }

    result += "))";
    return result;
  }

  this.writeRecordMember = function (member) {
    return member.name + ":" + member.type;
  }

  this.writeTextType = function (type) {
    var result = "Text([1,";

    if (type.maximumLength !== null)
      result += type.maximumLength;

    result += "]"

    if (type.lines !== null) {
      result += ",Lines:";
      result += type.lines;
    }

    result += ")";

    return result;
  }
}

function StructuredTypeBuilder(element) {
  WebPageComponent.call(this, element);

  this.addRow = function () {
    this.table.tBodies[0].appendChild(this.createRow());
  }

  this.attachInteractivity = function () {
    var object = this;
    this.add.onclick = function (event) { object.addRow(); };
  }

  this.createCell = function (contents) {
    var cell;

    cell = document.createElement("td");
    cell.appendChild(contents);

    return cell;
  }

  this.createField = function (name) {
    var object = this;

    var field = document.createElement("INPUT");
    field.type = "text";
    field.name = name;
    field.onchange = function (event) { object.valueChanged(); };

    return field;
  }

  this.createTextArea = function (name) {
    var object = this;

    var field = document.createElement("textarea");
    field.rows = 1;
    field.name = name;
    field.onchange = function (event) { object.valueChanged(); };

    return field;
  }

  this.determineElements = function () {
    this.table = new DomQuery(this.element).getChild(WithTagName("TABLE"));
    this.add = new DomQuery(this.element).getDescendant(WithClass("Add"));
  }

  this.getLabel = function (name) {
    var index = 0;
    var result = null;
    var labels = this.getTypeComponent().getLabels();

    while (result === null && index < labels.length) {
      if (labels[index].Name === name)
        result = labels[index].Value;

      index++;
    }

    return result;
  }

  this.getTypeComponent = function () {
    return this.getAncestor(ComponentWithClass(TypeComponent));
  }

  this.removeRow = function (row) {
    this.table.tBodies[0].removeChild(row);
    this.valueChanged();
  }

  this.valueChanged = function () {
    this.updateValue();
    this.getTypeComponent().writeFields();
  }

  this.determineElements();
  this.attachInteractivity();
}

function EnumerationTypeBuilder(element) {
  StructuredTypeBuilder.call(this, element);

  this.createRow = function () {
    var object = this;

    var close = document.createElement("div");
    close.classList.add("Close");
    close.onclick = function (event) { object.removeRow(row) };

    var row = document.createElement("tr");
    row.appendChild(this.createCell(close));
    row.appendChild(this.createCell(this.createField("Value")));
    row.appendChild(this.createCell(this.createTextArea("Caption")));

    return row;
  }

  this.setValue = function (value) {
    this.type = value;
    this.table.tBodies[0].innerHTML = "";

    for (var index = 0; index < this.type.options.length; index++) {
      this.addRow();
      this.table.tBodies[0].rows[index].cells[1].childNodes[0].value = value.options[index];
      this.table.tBodies[0].rows[index].cells[2].childNodes[0].value = this.getLabel(value.options[index]);
    }
  }

  this.updateValue = function () {
    this.type = new Type("Enumeration");
    this.type.options = new Array();

    var labels = new Array();
    var rows = this.table.tBodies[0].rows;

    for (var index = 0; index < rows.length; index++) {
      var row = rows[index];
      var identifier = row.cells[1].childNodes[0].value;
      var label = row.cells[2].childNodes[0].value;

      this.type.options.push(identifier);

      labels.push({
        "Name": identifier,
        "Value": label
      });
    }

    this.getTypeComponent().setLabels(labels);
  }
}

function RecordTypeBuilder(element) {
  StructuredTypeBuilder.call(this, element);

  this.createRow = function () {
    var object = this;

    var close = document.createElement("div");
    close.classList.add("Close");
    close.onclick = function (event) { object.removeRow(row) };

    var row = document.createElement("tr");
    row.appendChild(this.createCell(close));
    row.appendChild(this.createCell(this.createField("Member.Name")));
    row.appendChild(this.createCell(this.createTextArea("Member.Caption")));
    row.appendChild(this.createCell(this.createField("Member.Type")));

    return row;
  }

  this.setValue = function (value) {
    this.type = value;
    this.table.tBodies[0].innerHTML = "";

    for (var index = 0; index < this.type.members.length; index++) {
      this.addRow();
      this.table.tBodies[0].rows[index].cells[1].childNodes[0].value = value.members[index].name;
      this.table.tBodies[0].rows[index].cells[2].childNodes[0].value = this.getLabel(value.members[index].name);
      this.table.tBodies[0].rows[index].cells[3].childNodes[0].value = value.members[index].type;
    }
  }

  this.updateValue = function () {
    this.type = new Type("RecordSet");
    this.type.members = new Array();

    var labels = new Array();
    var rows = this.table.tBodies[0].rows;

    for (var index = 0; index < rows.length; index++) {
      var row = rows[index];
      var name = row.cells[1].childNodes[0].value;
      var label = row.cells[2].childNodes[0].value;
      var value = row.cells[3].childNodes[0].value;

      this.type.members.push({
        "name": name,
        "type": value
      });

      labels.push({
        "Name": name,
        "Value": label
      });
    }

    this.getTypeComponent().setLabels(labels);
  }
}

interactivityRegistration.register("TypeComponent", function (element) { return new TypeComponent(element); });
interactivityRegistration.register("TypeField", function (element) { return new TypeField(element); });
interactivityRegistration.register("EnumerationBuilder", function (element) { return new EnumerationTypeBuilder(element); });
interactivityRegistration.register("RecordBuilder", function (element) { return new RecordTypeBuilder(element); });
