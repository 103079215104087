class DatePeriodSelectorPage extends PeriodSelectorPage {
  valueToText(date) {
    const monthText = (date.getMonth() + 1).toString().padStart(2, "0");
    const dayText = date.getDate().toString().padStart(2, "0");

    return `${date.getFullYear()}-${monthText}-${dayText}`;
  }
}

class DatePeriodSelector extends PeriodSelector {
  newPage() {
    return new DatePeriodSelectorPage(
      this.periodStart,
      this.periodEnd,
      this.dayLabels,
      this.referenceDate.getFullYear(),
      this.referenceDate.getMonth(),
      this.period
    );
  }

  parseValue(dateString) {
    let date = null;

    if (dateString.length === 10) {
      const parts = dateString.split("-");

      if (parts.length === 3)
        date = new Date(parts[0], parts[1] - 1, parts[2]);
    }

    return date;
  }
}

class DatePeriodField extends PeriodField {
  constructor(element) {
    super(element);
  }

  createSelector() {
    this.periodSelector = new DatePeriodSelector(this.start, this.end, this.monthLabels, this.dayLabels, this.todayLabel, () => this.submit());
    this.selector.appendChild(this.periodSelector.element);
  }

  determineInputFields() {
    const query = new DomQuery(this.element);

    this.start = query.getChild(WithClass("Start"));
    this.end = query.getChild(WithClass("End"));
  }

  isValid(value) {
    if (/^\d{4}-\d{2}-\d{2}$/.test(value)) {
      const [year, month, day] = value.split("-").map(Number);
      const date = new Date(year, month - 1, day);

      return (year >= 1 && month >= 1 && month <= 12 && day >= 1 && day <= 31)
        && (date.getFullYear() === year && date.getMonth() + 1 === month && date.getDate() === day);
    }
    else
      return false;
  }
}

interactivityRegistration.register("DatePeriod", function (element) { return new DatePeriodField(element); });
