class DecimalField extends DefaultFormField {
  constructor(element) {
    super(element);

    this.determineElements();
    this.attachHandlers();
  }

  attachHandlers() {
    if (this.input !== null) {
      this.addValueChangedHandler(this.input);
      this.addBlurHandler(this.input);
    }
  }

  checkInput(problemTarget) {
    super.checkInput(problemTarget);

    const value = this.getValue();

    if (value !== null && !/^-?\d+(\.\d+)?$/.test(value))
      problemTarget.addProblem(this, this.element.dataset.InvalidValueMessage);
  }

  determineElements() {
    this.input = this.element;
  }
}

interactivityRegistration.register("DecimalField", function (element) { return new DecimalField(element); });
