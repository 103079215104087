function Timer(delay) {
  this.delay = delay;
  this.isScheduled = false;
  this.timerID = 0;

  this.schedule = function (eventHandler) {
    this.isScheduled = true;
    this.timerID = window.setTimeout(eventHandler, this.delay);
  }

  this.kill = function () {
    if (this.isScheduled)
      window.clearTimeout(this.timerID);
  }
}
