class ExpressionField extends DefaultFormField {
  constructor(element) {
    super(element);

    this.determineElements();
    this.attachHandlers();
    this.element.tabIndex = 0;
  }

  attachHandlers() {
    if (this.mode === ControlMode.edit) {
      this.element.addEventListener("click", (event) => { this.focus(); });
      this.element.addEventListener("focus", (event) => { this.focus(); });

      this.input.addEventListener("blur", (event) => { this.blur(); });
      this.input.addEventListener("change", (event) => { this.reload() });

      this.addValueChangedHandler(this.input);
      this.addBlurHandler(this.input);
    }
  }

  blur() {
    this.element.classList.remove("Focus");
    this.element.tabIndex = 0;
  }

  focus() {
    this.element.classList.add("Focus");
    this.input.focus();

    this.element.tabIndex = -1;
  }

  determineElements() {
    this.expression = this.element.childNodes[0];

    if (this.mode === ControlMode.edit) {
      this.input = new DomQuery(this.element).getChild(WithTagName("TEXTAREA"));
      this.input.spellcheck = false;
    }
  }

  reload() {
    this.client.sendJsonRequest(
      this.element.dataset.Uri, {
      "Command": "Parse",
      "Value": this.input.value
    },
      (response) => {
        if (response.status === 200) {
          var dummy = document.createElement("div");
          dummy.innerHTML = response.responseText;

          this.element.replaceChild(dummy.firstChild.firstChild, this.element.firstChild);

          this.expression = this.element.firstChild;
          this.expression.addEventListener("click", (event) => { this.focus() });
        }
      }
    );
  }
}

interactivityRegistration.register("Expression", function (element) { return new ExpressionField(element); });
