function FieldCondition(field, value) {
  this.isMet = function () {
    var renderer = new ValueTextRenderer();
    return renderer.render(this.field.getValue()) === this.value;
  }

  this.field = field;
  this.value = value;
}

function ConditionalComponent(element) {
  WebPageComponent.call(this, element);

  this.determineElements = function () {
    var form = this.getAncestor(new ComponentWithClass(Form));
    var conditionElements = new DomQuery(this.element).getChildren(WithClass("FieldCondition"));

    this.conditions = new Array();

    for (var index = 0; index < conditionElements.length; index++) {
      var condition = conditionElements[index];
      var fieldName = condition.dataset.Name;
      var component = form.getDescendant(new ComponentWithName(fieldName));

      if (component !== null)
        this.conditions.push(new FieldCondition(component, condition.dataset.Value));
      else
        console.log("Cannot find field for element: " + fieldName);
    }
  }

  this.bind = function () {
    this.determineElements();
    this.attachEventHandlers();
    this.updateVisibility();
  }

  this.valueChanged = function () {
    this.updateVisibility();
  }

  this.updateVisibility = function () {
    var visible = false;
    var index = 0;

    while (!visible && index < this.conditions.length) {
      visible = this.conditions[index].isMet();
      index++;
    }

    this.visible = visible;

    if (this.hideSelf) {
      var visibility = new HtmlClassSwitch(this.element, "Hidden");
      visibility.setStatus(!this.visible);
    }
    else {
      var parent = this.parentComponent;

      if (parent !== null)
        parent.handleEvent(new VisibilityChangedEvent(this));
    }
  }

  this.attachEventHandlers = function () {
    var object = this;
    var fields = new Array(this.conditions.length);

    for (var index = 0; index < this.conditions.length; index++) {
      var condition = this.conditions[index];

      if (fields.indexOf(condition.field) == -1)
        condition.field.addEventListener("change", function (event) { object.valueChanged(); });

      fields[index] = condition.field;
    }
  }

  this.value = "";
  this.hideSelf = this.element.dataset.HideSelf === "true";
}

interactivityRegistration.register("ConditionalComponent", function (element) { return new ConditionalComponent(element); });
