class IntegerField extends DefaultFormField {
  constructor(element) {
    super(element);

    this.determineElements();
    this.attachHandlers();
  }

  attachHandlers() {
    this.addValueChangedHandler(this.input);
    this.addBlurHandler(this.input);
  }

  checkInput(problemTarget) {
    super.checkInput(problemTarget);

    const value = this.getValue();

    if (value !== null && !/^-?\d+$/.test(value))
      problemTarget.addProblem(this, this.element.dataset.InvalidValueMessage);
  }

  determineElements() {
    this.input = this.element;
  }
}

interactivityRegistration.register("IntegerField", function (element) { return new IntegerField(element); });
