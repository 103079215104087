class WebSiteToolbar extends WebPageComponentClass {
  constructor(element) {
    super(element);

    this.expanded = new HtmlClassSwitch(this.element, "Expanded");

    this.determineElements();
    this.attachHandlers();
  }

  attachHandlers() {
    this.toggle.addEventListener(
      "click",
      (event) => {
        this.expanded.toggle();
      }
    );
  }

  determineElements() {
    const query = new DomQuery(this.element);
    this.toggle = query.getChild(WithClass("Toggle"));

    const userMenu = query.getDescendant(WithClass("User"));

    if (userMenu)
      this.userMenu = new UserBox(userMenu);
  }
}

class UserBox extends WebPageComponentClass {
  constructor(element) {
    super(element);

    this.user = new DomQuery(this.element).getDescendant(WithClass("User"));
    this.determineUser();
  }

  async determineUser() {
    const store = new WebSiteStore();

    try {
      const response = await fetch(
        application.resolve("SignIn/Status"),
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      const data = await response.json();

      if (data.SignedIn)
        store.set("User", data.User);
      else
        store.delete("User");
    }
    catch (error) {
      const user = await store.get("User");

      if (user)
        this.user.innerText = user;
    }
  }
}

interactivityRegistration.register(
  "WebSiteToolbar",
  (element) => {
    return new WebSiteToolbar(element, true);
  }
);
