class DateTimePeriodSelectorPage extends PeriodSelectorPage {
  valueToText(date) {
    const monthText = (date.getMonth() + 1).toString().padStart(2, "0");
    const dayText = date.getDate().toString().padStart(2, "0");
    const hourText = date.getHours().toString().padStart(2, "0");
    const minuteText = date.getMinutes().toString().padStart(2, "0");
    const secondText = date.getSeconds().toString().padStart(2, "0");

    return `${date.getFullYear()}-${monthText}-${dayText} ${hourText}:${minuteText}:${secondText}`;
  }
}

class DateTimePeriodSelector extends PeriodSelector {
  newPage() {
    return new DateTimePeriodSelectorPage(
      this.periodStart,
      this.periodEnd,
      this.dayLabels,
      this.referenceDate.getFullYear(),
      this.referenceDate.getMonth(),
      this.period
    );
  }

  parseValue(dateString) {
    let dateTime = null;

    if (dateString.length === 19) {
      const parts = dateString.split(" ");

      if (parts.length === 2) {
        const dateParts = parts[0].split("-");
        const timeParts = parts[1].split(":");

        if (dateParts.length === 3 && timeParts.length === 3)
          dateTime = new Date(dateParts[0], dateParts[1] - 1, dateParts[2], timeParts[0], timeParts[1], timeParts[2]);
      }
    }

    return dateTime;
  }
}

class DateTimePeriodField extends PeriodField {
  constructor(element) {
    super(element);
  }

  createSelector() {
    this.periodSelector = new DateTimePeriodSelector(this.start, this.end, this.monthLabels, this.dayLabels, this.todayLabel, () => this.submit());
    this.selector.appendChild(this.periodSelector.element);
  }

  determineInputFields() {
    const query = new DomQuery(this.element);

    this.start = query.getChild(WithClass("Low"));
    this.end = query.getChild(WithClass("High"));
  }

  isValid(value) {
    if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(value)) {
      const [datePart, timePart] = value.split(" ");
      const [hour, minute, second] = timePart.split(":").map(Number);

      return this.isValidDate(datePart)
        && (hour >= 0 && hour <= 23 && minute >= 0 && minute <= 59 && second >=0 && second <= 59)
    }
    else
      return false;
  }

  isValidDate(value) {
    const [year, month, day] = value.split("-").map(Number);
    const date = new Date(year, month - 1, day);

    return (year >= 1 && month >= 1 && month <= 12 && day >= 1 && day <= 31)
      && (date.getFullYear() === year && date.getMonth() + 1 === month && date.getDate() === day);
  }
}

interactivityRegistration.register("DateTimePeriod", function (element) { return new DateTimePeriodField(element); });
