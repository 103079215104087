class EntityStore {
  constructor() {
    this.databaseName = "EntityStore";
    this.entityStoreName = "entities";
    this.database = null;

    this.databaseReady = this.openDatabase();
  }

  openDatabase() {
    return new Promise(
      (resolve, reject) => {
        const version = 1;
        const request = indexedDB.open(this.databaseName, version);

        request.onsuccess = (event) => {
          this.database = event.target.result;
          resolve();
        };

        request.onupgradeneeded = (event) => {
          const database = event.target.result;
          database.createObjectStore(this.entityStoreName, { keyPath: "Uri" });
        };

        request.onerror = (event) => {
          reject(event.target.error);
        };
      }
    );
  }

  performTransaction(storeName, mode, callback) {
    return this.databaseReady.then(
      () => new Promise(
        (resolve, reject) => {
          const transaction = this.database.transaction([storeName], mode);
          const store = transaction.objectStore(storeName);

          callback(store, transaction, resolve, reject);
        }
      )
    );
  }

  add(entity) {
    return this.performTransaction(
      this.entityStoreName,
      "readwrite",
      (store, transaction, resolve, reject) => {
        transaction.oncomplete = () => resolve(entity);
        transaction.onerror = (event) => reject(event.target.error);
        store.put(entity);
      }
    );
  }

  delete(key) {
    return this.performTransaction(
      this.entityStoreName,
      "readwrite",
      (store, transaction, resolve, reject) => {
        transaction.oncomplete = resolve;
        transaction.onerror = (event) => reject(event.target.error);
        store.delete(key);
      }
    );
  }

  deleteMatches(indexName, value) {
    return this.performTransaction(
      this.entityStoreName,
      "readwrite",
      (store, transaction, resolve, reject) => {
        const index = store.index(indexName);
        const range = IDBKeyRange.only(value);
        const request = index.openCursor(range);

        request.onsuccess = () => {
          const cursor = request.result;

          if (cursor) {
            cursor.delete();
            cursor.continue();
          }
        };

        transaction.oncomplete = resolve;
        transaction.onerror = (event) => reject(event.target.error);
      }
    );
  }

  get(key) {
    return this.performTransaction(
      this.entityStoreName,
      "readonly",
      (store, transaction, resolve, reject) => {
        const request = store.get(key);
        request.onsuccess = () => resolve(request.result);
        request.onerror = (event) => reject(event.target.error);
      }
    );
  }

  getAll() {
    return this.performTransaction(
      this.entityStoreName,
      "readonly",
      (store, transaction, resolve, reject) => {
        const request = store.getAll();
        request.onsuccess = () => resolve(request.result);
        request.onerror = (event) => reject(event.target.error);
      }
    );
  }

  update(entity) {
    return this.performTransaction(
      this.entityStoreName,
      "readwrite",
      (store, transaction, resolve, reject) => {
        transaction.oncomplete = resolve;
        transaction.onerror = (event) => reject(event.target.error);
        store.put(entity);
      }
    );
  }
}
