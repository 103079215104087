function HttpClient() {
  this.handleResponse = function (xmlRequest, handler) {
    if (xmlRequest.readyState === 4)
      handler(xmlRequest);
  };

  this.get = function (uri, handler, progress) {
    var client = this;
    var xmlRequest = newXmlRequest();

    xmlRequest.open("GET", uri, true);
    xmlRequest.onreadystatechange = function () { client.handleResponse(xmlRequest, handler) };

    this.updateProgress(xmlRequest, progress);

    xmlRequest.send();
  }

  this.postFormRequest = function (uri, form, handler, progress) {
    var client = this;
    var xmlRequest = newXmlRequest();

    xmlRequest.open("POST", uri, true);
    xmlRequest.onreadystatechange = function () { client.handleResponse(xmlRequest, handler) };

    this.updateProgress(xmlRequest, progress);

    xmlRequest.send(form);
  }

  this.sendJsonRequest = function (uri, object, handler, progress) {
    var client = this;
    var xmlRequest = newXmlRequest();

    xmlRequest.open("POST", uri, true);
    xmlRequest.setRequestHeader("Content-Type", "application/json");
    xmlRequest.onreadystatechange = function () { client.handleResponse(xmlRequest, handler) };

    this.updateProgress(xmlRequest, progress);

    xmlRequest.send(JSON.stringify(object));
  }

  this.sendXmlRequest = function (uri, content, handler, progress) {
    var client = this;
    var xmlRequest = newXmlRequest();

    xmlRequest.open("POST", uri, true);
    xmlRequest.setRequestHeader("Content-Type", "application/xml");
    xmlRequest.onreadystatechange = function () { client.handleResponse(xmlRequest, handler) };

    this.updateProgress(xmlRequest, progress);

    xmlRequest.send(content);
  };

  this.updateProgress = function (xmlRequest, progress) {
    if (progress !== undefined) {
      xmlRequest.onloadstart = function (event) { progress.setProgress(1) };
      xmlRequest.onloadend = function (event) { progress.setProgress(100) };
      xmlRequest.onload = function (event) { progress.setProgress(100) };
      xmlRequest.onabort = function (event) { progress.setProgress(0) };
      xmlRequest.onerror = function (event) { progress.setProgress(0) };
      xmlRequest.ontimeout = function (event) { progress.setProgress(0) };
    }
  }
}
