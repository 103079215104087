class FormSection extends WebPageComponentClass {
  constructor(element) {
    super(element);

    this.setUp();
  }

  focus() {
    var target = this.childComponents.find(child => child.isFocusable());

    if (target !== undefined)
      target.focus();
  }

  setUp() {
    for (var index = 0; index < this.element.tBodies[0].rows.length; index++) {
      var row = this.element.tBodies[0].rows[index];
      var cell = row.cells[1];
      var element = cell.firstChild;

      if (element !== null && element.component !== undefined)
        this.updateRowVisibility(row, element.component);
    }
  }

  getRowWithComponent(component) {
    var result = null;
    var index = 0;

    while (result === null && index < this.element.tBodies[0].rows.length) {
      var row = this.element.tBodies[0].rows[index];

      if (row.contains(component.element))
        result = row;
      else
        index++;
    }

    return result;
  }

  updateRowVisibility(row, component) {
    var visibility = new HtmlClassSwitch(row, "Hidden");
    visibility.setStatus(!component.visible);
  }

  handleEvent(event) {
    if (event instanceof VisibilityChangedEvent) {
      var row = this.getRowWithComponent(event.field);

      if (row !== null)
        this.updateRowVisibility(row, event.field);
    }
  }

  getProblems(field) {
    const cell = field.element.parentNode;
    return new DomQuery(cell).getChild(WithClass("Problems"));
  }

  addProblem(field, message) {
    const cell = field.element.parentNode;
    const row = cell.parentNode;

    const problems = this.getProblems(field);

    if (problems !== null) {
      const problem = document.createElement("span");
      problem.classList.add("Problem");
      problem.classList.add("Error");
      problem.innerText = message;

      problems.appendChild(problem);

      new HtmlClassSwitch(row, "Error").setStatus(true);
      new HtmlClassSwitch(row, "Ok").setStatus(false);
    }
  }

  clearProblems(field) {
    const problems = this.getProblems(field);

    if (problems !== null)
      problems.innerHTML = "";

    new HtmlClassSwitch(field.element.parentNode.parentNode, "Error").setStatus(false);
    new HtmlClassSwitch(field.element.parentNode.parentNode, "Ok").setStatus(field.value !== null);
  }
}

interactivityRegistration.register("FormSection", function (element) { return new FormSection(element); });
