class CheckBoxField extends FormField {
  constructor(element) {
    super(element);

    this.input = new DomQuery(this.element).getDescendant(WithTagName("INPUT"));

    if (this.input !== null && !this.input.disabled) {
      this.element.tabIndex = 0;
      this.element.addEventListener("keydown", this.createKeyListener());

      this.addValueChangedHandler(this.input);
      this.addBlurHandler(this.input);
    }
  }

  addEventListener(event, handler) {
    this.input.addEventListener(event, handler);
  }

  createKeyListener() {
    var object = this;

    return function (event) {
      if (event.code === "Enter" || event.code === "Space") {
        object.setValue(!object.getValue());
        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  getValue() {
    if (this.input.indeterminate)
      return null
    else
      return this.input.checked;
  }

  setValue(value) {
    this.input.indeterminate = value === null;

    if (!this.input.indeterminate)
      this.input.checked = value;

    this.input.dispatchEvent(new Event("change"));
  }
}

interactivityRegistration.register("CheckBoxField", function (element) { return new CheckBoxField(element); });
