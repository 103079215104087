class TextField extends DefaultFormField {
  constructor(element) {
    super(element);
  }

  attachHandlers() {
    if (this.input !== null) {
      this.input.addEventListener("focus", (event) => { this.element.classList.add("Focus"); });
      this.input.addEventListener("blur", (event) => { this.element.classList.remove("Focus"); });

      this.addValueChangedHandler(this.input);
      this.addBlurHandler(this.input);

      if (this.checkBox !== null)
        this.checkBox.addEventListener("change", (event) => { this.valueChanged(); });
    }
  }

  determineElements() {
    this.checkBox = new DomQuery(this.element).getChild(WithClass("CheckBoxField"));

    if (this.checkBox !== null)
      this.checkBox = this.checkBox.childNodes[0];

    this.determineInputElement();
  }

  checkInput(problemTarget) {
    super.checkInput(problemTarget);

    if (this.value !== null && this.value.length < this.minimumLength)
      problemTarget.addProblem(this, this.element.dataset.OutOfRangeMessage);
    else if (this.value !== null && this.value.length > this.maximumLength)
      problemTarget.addProblem(this, this.element.dataset.OutOfRangeMessage);
  }

  getValue() {
    return (this.minimumLength > 0 && this.input.value === "") ? null : this.input.value;
  }

  disable() {
    this.element.classList.add("Disabled");
  }

  enable() {
    this.element.classList.remove("Disabled");
  }

  valueChanged() {
    this.input.readOnly = this.checkBox.checked;

    if (this.checkBox.checked)
      this.disable();
    else
      this.enable();
  }

  get minimumLength() {
    return this.element.dataset.MinimumLength ?? 0;
  }

  get maximumLength() {
    return this.element.dataset.MaximumLength ?? Infinity
  }
}

class SingleLineTextField extends TextField {
  constructor(element) {
    super(element);

    this.determineElements();
    this.attachHandlers();
  }

  determineInputElement() {
    this.input = new DomQuery(this.element).getChild(WithTagName("INPUT"));
  }
}

class MultipleLineTextField extends TextField {
  constructor(element) {
    super(element);

    this.determineElements();
    this.attachHandlers();
  }

  determineInputElement() {
    this.input = new DomQuery(this.element).getChild(WithTagName("TEXTAREA"));
  }
}

interactivityRegistration.register("SingleLineTextField", function (element) { return new SingleLineTextField(element); });
interactivityRegistration.register("MultipleLineTextField", function (element) { return new MultipleLineTextField(element); });
