class ProblemTarget {
  constructor() {
    this.problems = new Map();
  }

  clearProblems(field) {
    this.problems.set(field, new Array());
  }

  addProblem(field, problem) {
    this.problems.get(field).push(problem);
  }

  isValid() {
    return this.someValid() && Array.from(this.problems.values()).every(problems => problems.length === 0);
  }

  someValid() {
    return Array.from(this.problems.entries()).some(
      ([field, problems]) => {
        const value = field.getValue();
        return (!field.optional || (value !== null && value !== "")) && problems.length === 0;
      }
    )
  }
}

class WizardFormSection {
  constructor(selector, section) {
    this.selector = selector;
    this.section = section;

    const formSection = new DomQuery(this.section).getDescendant(WithClass("FormSection")).component;

    if (formSection !== null)
      this.fields = formSection.childComponents;
    else
      this.fields = new Array();

    this.toolbar = document.createElement("div");
    this.toolbar.classList.add("Toolbar");

    section.appendChild(this.toolbar);

    this.inProgress = new HtmlClassSwitch(this.selector, "InProgress");
    this.completed = new HtmlClassSwitch(this.selector, "Completed");

    this.attachHandlers();
    this.validate();
  }

  attachHandlers() {
    for (const field of this.fields)
      field.addEventListener("change", (event) => this.validate());
  }

  addButton(className, action) {
    const button = document.createElement("button");
    button.classList.add(className);

    if (action) {
      button.type = "button";
      button.addEventListener("click", action);
    }

    this.toolbar.appendChild(button);
  }

  validate() {
    const problems = new ProblemTarget();

    for (const field of this.fields)
      field.validate(problems);

    this.completed.setStatus(problems.isValid());
    this.inProgress.setStatus(!this.completed.getStatus() && problems.someValid());
  }

  isInProgress() {
    return this.fields.some(element => element.inputOk);
  }

  isCompleted() {
    return this.fields.every(element => element.inputOk);
  }

  load(value) {
    for (const property in value) {
      const field = this.fields.find(element => element.name === property);

      if (field)
        field.load(value[property]);
    }
  }

  save() {
    const result = {};

    for (const field of this.fields)
      result[field.name] = field.save();

    return result;
  }

  select() {
    this.selector.classList.add("Active");
    this.section.classList.add("Active");

    this.validate();
  }

  deselect() {
    this.selector.classList.remove("Active");
    this.section.classList.remove("Active");

    this.validate();
  }

  get name() {
    return this.section.dataset.Name;
  }
}

class WizardForm extends WebPageComponentClass {
  constructor(element) {
    super(element);

    this.sections = new Array();
    this.labels = new Labels(new DomQuery(this.element).getChild(WithClass("Labels")));
  }

  bind() {
    this.initialize();

    this.sections[0].select();
    this.active = this.sections[0];
  }

  load(value) {
    for (const property in value) {
      const section = this.sections.find(element => element.name === property);

      if (section)
        section.load(value[property]);
    }
  }

  save() {
    const result = {};

    for (const section of this.sections)
      result[section.name] = section.save();

    return result;
  }

  initialize() {
    const selectorsElement = new DomQuery(this.element).getChild(WithClass("Sections"));
    const selectorElements = new DomQuery(selectorsElement).getChildren(WithClass("Section"));

    for (let index = 0; index < selectorElements.length; index++) {
      const selectorElement = selectorElements[index];

      const section = new WizardFormSection(selectorElement, new DomQuery(this.element).getChild((element) => { return selectorElement.dataset.Name === element.dataset.Name }));
      section.selector.addEventListener(
        "click",
        (event) => {
          this.select(section);
        }
      );

      if (index > 0)
        section.addButton("Previous", (event) => { this.select(this.sections[index - 1]); });

      if (index < selectorElements.length - 1)
        section.addButton("Next", (event) => { this.select(this.sections[index + 1]); });

      if (index === selectorElements.length - 1)
        section.addButton(
          "Submit",
          (event) => {
            application.pageHandler.persistOfflinePage();
            application.toastBox.addMessage(new ToastMessage(this.labels.SuccessfulSubmission, "Success"));
          }
        );

      this.sections.push(section);
    }
  }

  select(target) {
    if (this.active !== target) {
      this.active.validate();

      this.sections.forEach(
        (section) => {
          if (section !== target)
            section.deselect();
        }
      );

      target.select();

      this.active = target;
      this.element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    }
  }

  get name() {
    return this.element.dataset.Name;
  }
}
