function HtmlClasses(element) {
  this.element = element;
  this.classes = element.className.split(" ");

  this.updateElement = function () {
    this.element.className = this.classes.join(" ");
  }

  this.indexOf = function (class_) {
    var result = -1;
    var i = 0;

    while (result === -1 && i < this.classes.length) {
      if (this.classes[i] === class_)
        result = i;
      else
        i++;
    }

    return result;
  }

  this.add = function (class_) {
    var index = this.indexOf(class_);

    if (index === -1) {
      this.classes.push(class_);
      this.updateElement();
    }
  }

  this.remove = function (class_) {
    var index = this.indexOf(class_);

    if (index !== -1) {
      this.classes.splice(index, 1);
      this.updateElement();
    }
  }

  this.toggle = function (class_) {
    var index = this.indexOf(class_);

    if (index !== -1)
      this.classes.splice(index, 1);
    else
      this.classes.push(class_);

    this.updateElement();
  }

  this.contains = function (class_) {
    return this.indexOf(class_) !== -1;
  }

  this.setPresent = function (class_, value) {
    if (value)
      this.add(class_);
    else
      this.remove(class_);
  }
}

function HtmlClassSwitch(element, class_) {
  this.element = element;
  this.class_ = class_;

  this.getStatus = function () {
    return new HtmlClasses(this.element).contains(this.class_);
  }

  this.setStatus = function (value) {
    new HtmlClasses(this.element).setPresent(this.class_, value);
  }

  this.toggle = function () {
    new HtmlClasses(this.element).toggle(this.class_);
  }
}

function HtmlClassCounter(element, class_) {
  this.switch_ = new HtmlClassSwitch(element, class_);
  this.count = 0;

  this.increase = function () {
    this.count++;
    this.switch_.setStatus(this.count > 0);
  }

  this.decrease = function () {
    this.count--;
    this.switch_.setStatus(this.count > 0);
  }
}

function WithClass(class_) {
  return function (node) { return nodeHasClass(node, class_); }
}

function WithId(id) {
  return function (node) { return node.id === id; }
}

function WithTagName(tagName) {
  return function (node) { return node.tagName === tagName; }
}

function DomTraversalFrame(element) {
  this.element = element;
  this.childIndex = 0;

  this.isAtEnd = function () {
    return this.childIndex === this.element.childNodes.length;
  }

  this.getNext = function () {
    var result = this.element.childNodes[this.childIndex];
    this.childIndex++;

    return result;
  }
}

function DomQuery(element) {
  this.element = element;

  this.getNthChild = function (condition, index) {
    var result = null;
    var i = 0;

    while (result === null && i < this.element.childNodes.length) {
      var node = this.element.childNodes[i];

      if (node.nodeType == "1" && condition(node)) {
        if (index === 0)
          result = node;
        else
          index--;
      }

      i++;
    }

    return result;
  }

  this.getChild = function (condition) {
    return this.getNthChild(condition, 0);
  }

  this.getChildren = function (condition) {
    var result = new Array();

    for (var i = 0; i < this.element.childNodes.length; i++) {
      var childNode = this.element.childNodes[i];

      if (childNode.nodeType == "1" && condition(childNode))
        result.push(childNode);
    }

    return result;
  }

  this.getDescendant = function (condition) {
    var result = null;
    var stack = new Array();

    for (var i = this.element.childNodes.length - 1; i >= 0; i--)
      stack.unshift(this.element.childNodes[i]);

    while (result === null && stack.length > 0) {
      var node = stack.shift();

      if (node.nodeType == "1") {
        if (condition(node))
          result = node;
        else {
          for (var i = node.childNodes.length - 1; i >= 0; i--)
            stack.unshift(node.childNodes[i]);
        }
      }
    }

    return result;
  }

  this.getDescendants = function (condition) {
    var result = new Array();
    var stack = new Array();

    for (var i = 0; i < this.element.childNodes.length; i++)
      stack.unshift(this.element.childNodes[i]);

    while (stack.length > 0) {
      var node = stack.shift();

      if (node.nodeType == "1") {
        if (condition(node))
          result.unshift(node);

        for (var i = 0; i < node.childNodes.length; i++)
          stack.unshift(node.childNodes[i]);
      }
    }

    return result;
  }

  this.hasAncestor = function (node) {
    var result = false;
    var current = this.element;

    while (!result && current !== null) {
      result = current === node;
      current = current.parentNode;
    }

    return result;
  }

  this.getAncestor = function (condition) {
    var result = null;
    var current = this.element;

    while (result === null && current !== null) {
      if (condition(current))
        result = current;
      else
        current = current.parentNode;
    }

    return result;
  }

  this.traversePostOrder = function (visit) {
    var stack = new Array();
    stack.push(new DomTraversalFrame(this.element));

    while (stack.length > 0) {
      var frame = stack[stack.length - 1];

      if (frame.isAtEnd()) {
        stack.pop();
        visit(frame.element);
      }
      else {
        var node = frame.getNext();

        if (node.nodeType == "1")
          stack.push(new DomTraversalFrame(node));
      }
    }
  }
}

function DomPathQuery(element) {
  this.element = element;

  this.getChild = function (condition) {
    return new DomPathQuery(new DomQuery(this.element).getChild(condition));
  }

  this.getDescendant = function (condition) {
    return new DomPathQuery(new DomQuery(this.element).getDescendant(condition));
  }

  this.getElement = function () {
    return this.element;
  }
}
