const StyleMode = new Enumeration(["Initial", "Hover", "Select"]);
const MapMode = new Enumeration(["Normal", "Light", "Dark"]);

function WebPageMapComponent(element) {
  WebPageComponent.call(this, element);

  this.attachHandlers = function () {
    var component = this;

    this.layerButton.onclick = function (event) {
      if (!component.locked)
        component.toggleComponent(component.layers, event);
    };

    if (this.legendButton !== null) {
      this.legendButton.onclick = function (event) {
        if (!component.locked)
          component.toggleComponent(component.legend, event);
      };
    }

    if (this.configurationButton !== null) {
      this.configurationButton.onclick = function (event) {
        if (!component.locked)
          component.toggleComponent(component.configuration, event);
      };
    }

    this.stylesheet.onchange = function (event) {
      if (!component.locked)
        component.update(
          function (event) {
            component.refresh();
          }
        );
    }

    this.restoreButton.onclick = function (event) {
      if (!component.locked)
        component.restore();
    };

    this.map.on("moveend",
      function (event) {
        if (!component.locked)
          component.update(
            function (event) {
              if (component.interactive)
                component.refresh();
            }
          );
      }
    );
  }

  this.bind = function () {
    this.map.updateSize();
  }

  this.createHoverInteraction = function () {
    var component = this;

    var hover = new ol.interaction.Select({
      condition: ol.events.condition.pointerMove,
      style: WebPageMapComponentHoverStyle
    });

    hover.on("select", function (event) {
      component.hover(event);
    });

    return hover;
  }

  this.applyFilter = function (context) {
    switch (this.mode) {
      case MapMode.Light: {
        context.filter = "grayscale(90%)";
        context.globalCompositeOperation = "source-over";

        break;
      }
      case MapMode.Dark: {
        context.filter = "grayscale(90%) invert(80%) hue-rotate(180deg)";
        context.globalCompositeOperation = "source-over";

        break;
      }
    }
  }

  this.createMap = function () {
    const mapLayer = new ol.layer.Tile({
      source: new ol.source.OSM({
        wrapX: false
      })
    });

    mapLayer.on(
      "precompose",
      (event) => {
        if (event.context)
          this.applyFilter(event.context);
      }
    );

    mapLayer.on(
      "postcompose",
      (event) => {
        if (event.context)
          event.context.filter = "none";
      }
    );

    mapLayer.setOpacity(this.opacity);

    this.map = new ol.Map({
      target: this.element.childNodes[0].id,
      layers: [
        mapLayer
      ],
      view: new ol.View({
        center: ol.proj.fromLonLat([0, 0]),
        zoom: this.zoomLevel
      })
    });

    this.hoverInteraction = this.createHoverInteraction();
    this.selectInteraction = this.createSelectInteraction();

    this.map.addInteraction(this.hoverInteraction);
    this.map.addInteraction(this.selectInteraction);

    this.layerSelectors[0].layer = mapLayer;
    this.layerSelectors[0].onchange = this.createToggleLayerHandler(mapLayer, this.layerSelectors[0].selector);
  }

  this.createRefreshHandler = function () {
    var object = this;

    return function (xmlResponse) {
      object.updateContents(JSON.parse(xmlResponse.response));
      object.unlock();
    }
  }

  this.createSelectInteraction = function () {
    var component = this;

    var select = new ol.interaction.Select({
      condition: ol.events.condition.select,
      style: WebPageMapComponentSelectStyle
    });
    select.on("select", function (event) { component.select(event); });

    return select;
  }

  this.createToggleLayerHandler = function (layer, selector) {
    var component = this;

    return function (event) {
      component.update(
        function () {
          component.updateLayer(layer, selector);
          component.updateLayers();
        }
      );
    };
  }

  this.determineElements = function () {
    var query = new DomQuery(this.element);

    this.tooltip = query.getChild(WithClass("Tooltip"));

    var actions = query.getChild(WithClass("Actions"));
    var query = new DomQuery(actions);

    this.components = query.getChild(WithClass("Components"));
    this.layerButton = query.getChild(WithClass("Layers"));
    this.legendButton = query.getChild(WithClass("Legend"));
    this.restoreButton = query.getChild(WithClass("Restore"));
    this.configurationButton = query.getChild(WithClass("Configuration"));

    var query = new DomQuery(this.components);

    this.legend = query.getChild(WithClass("Legend"));
    this.layers = query.getChild(WithClass("Layers"));
    this.inspector = query.getChild(WithClass("Inspector"));
    this.configuration = query.getChild(WithClass("Configuration"));

    var query = new DomQuery(this.configuration);
    this.stylesheet = query.getDescendant(WithTagName("TEXTAREA"));

    var query = new DomQuery(this.layers);

    this.layerSelectors = query.getDescendants(WithClass("Tristate"));

    for (var index = 0; index < this.layerSelectors.length; index++) {
      this.layerSelectors[index].selector = new Tristate(this.layerSelectors[index]);
      this.layerSelectors[index].selector.index = index;
    }
  }

  this.getBounds = function () {
    var size = this.map.getSize();
    var view = this.map.getView();

    var extent = view.calculateExtent(size);

    var result = ol.proj.transformExtent(
      extent,
      ol.proj.get("EPSG:3857"),
      ol.proj.get("EPSG:4326")
    );

    return result;
  }

  this.handleEvent = function (event) {
    var component = this;

    if (event instanceof VisibilityChangedEvent) {
      component.map.updateSize();
      component.updateViewPort(component.bounds, component.zoomLevel);
    }
    else if (event instanceof AvailableSizeChangedEvent) {
      component.map.updateSize();
      component.updateViewPort(component.bounds, component.zoomLevel);
    }
  }

  this.hasEnabledLayers = function () {
    var result = false;
    var i = 0;

    while (!result && i < this.layerSelectors.length) {
      result = this.layerSelectors[i].selector.state === "Enabled";
      i++;
    }

    return result;
  }

  this.createLegendTable = function (object, labels) {
    var exclude = ["style", "geometry", "orientation", "point-type", "point-source"];
    var table = document.createElement("table");
    var row, cell;

    var associatedValue = object.AssociatedValue;

    if (associatedValue !== undefined && associatedValue.length > 0) {
      row = table.insertRow();

      for (var property in associatedValue[0]) {
        var label = labels[property];

        cell = document.createElement("td");
        cell.innerHTML = label;

        row.appendChild(cell);
      }

      for (var element of associatedValue) {
        row = table.insertRow();

        for (var property in element) {
          cell = document.createElement("td");
          cell.innerHTML = element[property];

          row.appendChild(cell);
        }
      }
    }
    else {
      for (var property in object) {
        var label = labels[property];

        if (!exclude.includes(property) && label !== undefined) {
          row = table.insertRow();

          cell = document.createElement("td");
          cell.innerHTML = label;

          row.appendChild(cell);

          cell = document.createElement("td");
          cell.innerHTML = object[property];

          row.appendChild(cell);
        }
      }
    }

    if (table.rows.length === 0)
      table = null;

    return table;
  }

  this.hover = function (event) {
    var map = this;
    var source = event.mapBrowserEvent.originalEvent;
    var legend = this.tooltip;

    if (source.ctrlKey) {
      legend.innerHTML = "";

      if (event.selected.length > 0) {
        var feature = event.selected[0];
        var properties = feature.getProperties();
        var layer = map.hoverInteraction.getLayer(feature);

        var table = this.createLegendTable(properties, layer.labels);

        if (table !== null)
          legend.appendChild(table);
      }

      legend.style.position = "fixed";
      legend.style.left = source.clientX + "px";
      legend.style.top = source.clientY + "px";
    }
    else
      legend.innerHTML = "";
  }

  this.lock = function () {
    this.setLocked(true);
  }

  this.refresh = function () {
    this.lock();

    var component = this;
    component.client.sendJsonRequest(
      component.element.dataset.Uri,
      {
        "Command": "Refresh",
        "Value": {
          "ZoomLevel": component.map.getView().getZoom()
        }
      },
      component.createRefreshHandler()
    );
  }

  this.restore = function () {
    var component = this;

    this.lock();
    this.client.sendJsonRequest(
      this.element.dataset.Uri,
      { "Command": "Restore" },
      function (response) {
        var settings = JSON.parse(response.responseText);
        component.updateViewPort(settings.Bounds, settings.ZoomLevel);
        component.unlock();
      }
    );
  }

  this.select = function (event) {
    var map = this;
    var legend = this.inspector;
    legend.innerHTML = "";

    if (event.selected.length > 0) {
      var feature = event.selected[0];
      var properties = feature.getProperties();
      var layer = map.hoverInteraction.getLayer(feature);

      var table = this.createLegendTable(properties, layer.labels);

      if (table !== null)
        legend.appendChild(table);
    }
  }

  this.setLocked = function (locked) {
    this.locked = locked;
    this.lockedClass.setStatus(locked);
  }

  this.toggleComponent = function (component, event) {
    var object = this;
    var classes = new HtmlClasses(component);

    if (classes.contains("expanded"))
      removeClickOutsideListener(component.clickOutsideListener);
    else
      component.clickOutsideListener = connectClickOutsideListener(component, function (event) { object.toggleComponent(component, event); })

    classes.toggle("expanded");
    event.stopPropagation();
  }

  this.unlock = function () {
    this.setLocked(false);
  }

  this.update = function (callback) {
    var layers = new Array();

    for (var index = 1; index < this.layerSelectors.length; index++)
      layers.push(this.layerSelectors[index].selector.state);

    var bounds = this.getBounds();
    var bounds_ = null;

    if (!ol.extent.isEmpty(bounds)) {
      bounds_ = {
        "Left": bounds[0],
        "Top": bounds[1],
        "Right": bounds[2],
        "Bottom": bounds[3]
      };
    }

    this.client.sendJsonRequest(
      this.element.dataset.Uri,
      {
        "Command": "Update",
        "Value": {
          "Layers": layers,
          "Bounds": bounds_,
          "ZoomLevel": this.map.getView().getZoom(),
          "Stylesheet": this.stylesheet.value
        }
      },
      callback
    );
  }

  this.updateContents = function (contents) {
    var component = this;
    this.contents = contents;

    for (var index = 0; index < component.contents.length; index++) {
      var feature = component.contents[index];
      var layer = null;

      if (feature !== null) {
        var vectorSource = new ol.source.Vector({
          features: (new ol.format.GeoJSON()).readFeatures(feature, { featureProjection: ol.proj.get("EPSG:3857") })
        });

        if (feature.Class === "Vector") {
          layer = new ol.layer.Vector({
            source: vectorSource,
            style: WebPageMapComponentStyle
          });
        }
        else if (feature.Class === "Heatmap") {
          var gradient = feature.properties.Gradient.substring(1, feature.properties.Gradient.length - 1).split(",");
          var scale = feature.properties.Scale;

          layer = new ol.layer.Heatmap({
            source: vectorSource,
            radius: 10,
            blur: 2,
            gradient: gradient,
            weight: function (feature) {
              var weight = Math.max(0.25, parseFloat(feature.getProperties().style["stroke-width"]) / scale);
              return weight;
            }
          });
        }

        layer.setOpacity(feature.Opacity);
        layer.labels = feature.Labels;

        if (component.layerSelectors[index + 1].layer !== null)
          component.map.removeLayer(component.layerSelectors[index + 1].layer);

        if (layer !== null)
          component.map.addLayer(layer);
      }
      else if (component.layerSelectors[index + 1].layer !== null)
        component.map.removeLayer(component.layerSelectors[index + 1].layer);

      component.layerSelectors[index + 1].layer = layer;
      component.layerSelectors[index + 1].onchange = component.createToggleLayerHandler(layer, component.layerSelectors[index + 1].selector);
    }

    this.updateLayers();
  }

  this.updateLayer = function (layer, selector) {
    if (selector.state === "Enabled")
      selector.setEffect(true);
    else if (selector.state === "Neutral") {
      if (this.hasEnabledLayers())
        selector.setEffect(false);
      else
        selector.setEffect(true);
    }
    else if (selector.state === "Disabled")
      selector.setEffect(false);

    if (layer !== null)
      layer.setVisible(selector.effect);

    return (layer === null && selector.effect);
  }

  this.updateLayers = function () {
    var refresh = false;

    for (var index = 0; index < this.layerSelectors.length; index++)
      refresh = refresh || this.updateLayer(this.layerSelectors[index].layer, this.layerSelectors[index].selector);

    if (refresh)
      this.refresh();
  }

  this.updateViewPort = function (bounds, zoomLevel) {
    var extent = ol.extent.boundingExtent([[bounds[0], bounds[1]], [bounds[2], bounds[3]]]);

    if (!ol.extent.isEmpty(extent)) {
      extent = ol.proj.transformExtent(extent, ol.proj.get("EPSG:4326"), ol.proj.get("EPSG:3857"));

      if (zoomLevel === -1)
        this.map.getView().fit(extent, this.map.getSize());
      else
        this.map.getView().setCenter(ol.extent.getCenter(extent));
    }
  }

  this.zoomLevel = parseFloat(element.dataset.ZoomLevel);
  this.bounds = [parseFloat(element.dataset.Left), parseFloat(element.dataset.Top), parseFloat(element.dataset.Right), parseFloat(element.dataset.Bottom)];
  this.opacity = parseFloat(element.dataset.Opacity);
  this.interactive = element.dataset.Interactive === "true";
  this.mode = MapMode.fromText(element.dataset.Mode);
  this.locked = false;
  this.lockedClass = new HtmlClassSwitch(this.element, "Locked");

  this.map = null;

  this.layers = null;
  this.inspector = null;

  this.determineElements();
  this.createMap();

  this.map.render();
  this.updateViewPort(this.bounds, this.zoomLevel);
  this.attachHandlers();
  this.refresh();
}

function WebPageMapComponentStyle(feature, resolution) {
  var styleFunction = new WebPageMapComponentStyleFunction();

  var fill = styleFunction.getFill(feature);
  var stroke = styleFunction.getStroke(feature);

  var styles = new Array();
  var style = styleFunction.createStyle(feature, fill, stroke);

  if (style !== null)
    styles.push(style);

  return styles;
}

function WebPageMapComponentStyleFunction() {
  this.createStyle = function (feature, fill, stroke) {
    var result = null;
    var type = feature.getGeometry().getType();

    if (type === "Point")
      result = this.createPointStyle(feature, fill, stroke);
    else
      result = this.createRegularStyle(fill, stroke);

    return result;
  }

  this.createPointStyle = function (feature, fill, stroke) {
    var size = feature.getProperties().style["stroke-width"];
    var pointstyle = feature.get("point-type");
    var orientation = feature.get("orientation");
    var result = null;

    if (pointstyle === "custom" && isChrome()) {
      result = new ol.style.Style({
        image: new ol.style.Icon({
          crossOrigin: "anonymous",
          color: fill,
          opacity: fill[3],
          src: "/Resource/" + feature.get("point-source"),
          imgSize: [size, size],
          rotation: orientation * Math.PI / 180,
        })
      });
    }
    else if (pointstyle !== "custom") {
      result = new ol.style.Style({
        image: new ol.style.Circle({
          radius: size,
          fill: new ol.style.Fill({
            color: fill
          }),
          stroke: new ol.style.Stroke({
            color: stroke.getColor(),
            lineDash: stroke.getLineDash()
          })
        })
      });
    }

    return result;
  }

  this.createRegularStyle = function (fill, stroke) {
    var result = null;

    if (fill != null && stroke != null) {
      result = new ol.style.Style({
        fill: new ol.style.Fill({
          color: fill
        }),
        stroke: stroke
      });
    }
    else if (fill != null) {
      result = new ol.style.Style({
        fill: new ol.style.Fill({
          color: fill
        })
      });
    }
    else if (stroke != null) {
      result = new ol.style.Style({
        stroke: stroke
      });
    }

    return result;
  }

  this.getFill = function (feature) {
    var style = feature.getProperties().style;
    var fill = ol.color.asArray(style.fill);

    if (style.opacity != null) {
      if (fill != null) {
        fill = fill.slice();
        fill[3] = style.opacity;
      }
    }

    return fill;
  }

  this.getStroke = function (feature) {
    var style = feature.getProperties().style;
    var stroke = ol.color.asArray(style.stroke);

    if (style.opacity != null) {
      if (stroke != null) {
        stroke = stroke.slice();
        stroke[3] = style.opacity;
      }
    }

    var strokeWidth = style["stroke-width"];
    var strokePattern = style["stroke-pattern"];

    return new ol.style.Stroke({
      color: stroke,
      width: strokeWidth,
      lineDash: strokePattern
    });
  }
}

function WebPageMapComponentHoverStyle(feature, resolution) {
  var styleFunction = new WebPageMapComponentStyleFunction();
  var strokeWidth = feature.getProperties().style["stroke-width"];

  var fill = styleFunction.getFill(feature);
  var stroke = new ol.style.Stroke({
    width: strokeWidth,
    color: "#1677b8"
  });

  var styles = new Array();
  var style = styleFunction.createStyle(feature, fill, stroke);

  if (style !== null)
    styles.push(style);

  return styles;
}

function WebPageMapComponentSelectStyle(feature, resolution) {
  var styleFunction = new WebPageMapComponentStyleFunction();
  var styles = new Array();

  var strokeWidth = feature.getProperties().style["stroke-width"];

  var fill = styleFunction.getFill(feature);
  var stroke = new ol.style.Stroke({
    width: strokeWidth + 2,
    color: "#FFFFFF"
  });

  var style = styleFunction.createStyle(feature, fill, stroke);

  if (style !== null)
    styles.push(style);

  var fill = [22, 119, 184, 0.25];
  var stroke = new ol.style.Stroke({
    width: strokeWidth,
    color: "#1677b8"
  });

  var style = styleFunction.createStyle(feature, fill, stroke);

  if (style !== null)
    styles.push(style);

  return styles;
}

interactivityRegistration.register("Map", function (element) { return new WebPageMapComponent(element); });
