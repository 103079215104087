class MultipleSelectDropDown extends FormField {
  constructor(element) {
    super(element);

    this.element.tabIndex = 0;
    this.options = new Array();

    this.determineElements();
    this.recalculate();
    this.attachHandlers();
  }

  addEventListener(event, handler) {
    this.input.addEventListener(event, handler);
  }

  attachHandlers() {
    var object = this;
    this.button.addEventListener("click", function (event) { object.toggleForm(); });
    this.label.addEventListener("click", function (event) { object.toggleForm(); });

    for (var option of this.options) {
      option.element.addEventListener("click", this.createToggleOptionHandler(option));
      option.element.addEventListener("keydown", this.createOptionKeyHandler(option));
    }

    this.element.addEventListener("keydown", this.createKeyHandler());
    this.addValueChangedHandler(this.input);

    this.element.addEventListener(
      "blur",
      (event) => {
        if (!this.dropDownToggle.getStatus() && event.relatedTarget !== null)
          this.validate();
      }
    );
  }

  close() {
    this.dropDownToggle.setStatus(false);
    removeClickOutsideListener(this.clickOutsideListener);
  }

  createKeyHandler() {
    var object = this;

    return function (event) {
      return object.handleKey(event);
    }
  }

  createOptionKeyHandler(option) {
    var object = this;

    return function (event) {
      return object.handleOptionKey(option, event);
    }
  }

  createToggleOptionHandler(option) {
    var object = this;

    return function (event) {
      object.toggle(option);
      return true;
    }
  }

  determineElements() {
    var query = new DomQuery(this.element);

    this.dropDown = query.getChild(WithClass("Options"));
    this.dropDownToggle = new HtmlClassSwitch(this.dropDown, "Expanded");

    this.button = query.getChild(WithTagName("BUTTON"));
    this.button.tabIndex = -1;

    this.label = query.getChild(WithClass("Label"));
    this.input = query.getChild(WithTagName("INPUT"));

    var options = new DomQuery(this.dropDown).getChildren(WithClass("Option"));

    for (var option of options)
      this.options.push(new MultipleSelectOption(option));
  }

  focus() {
    this.options[0].element.focus();
  }

  focusNext(option) {
    var target = option.element.nextSibling;

    if (target !== null && target.classList.contains("Option"))
      target.focus();
  }

  focusPrevious(option) {
    var target = option.element.previousSibling;

    if (target !== null && target.classList.contains("Option"))
      target.focus();
  }

  getValue() {
    return this.input.value;
  }

  handleKey(event) {
    if (event.ctrlKey && event.code === "Space") {
      this.toggleForm();
      event.stopPropagation();
    }
  }

  handleOptionKey(option, event) {
    if (event.code === "Space") {
      this.toggle(option);
      event.stopPropagation();
    }
    else if (event.code === "Enter") {
      this.toggle(option);

      if (this.isOpen())
        this.close();
    }
    else if (this.isOpen() && event.code === "Escape") {
      this.close();
    }
    else if (event.code === "ArrowUp") {
      this.focusPrevious(option);
      event.preventDefault();
    }
    else if (event.code === "ArrowDown") {
      this.focusNext(option);
      event.preventDefault();
    }
    else if (event.code === "Home") {
      this.options[0].element.focus();
      event.preventDefault();
    }
    else if (event.code === "End") {
      this.options[this.options.length - 1].element.focus();
      event.preventDefault();
    }
    else if (event.ctrlKey && event.code === "KeyA") {
      this.selectAll();
      event.preventDefault();
    }
  }

  isOpen() {
    return this.dropDownToggle.getStatus();
  }

  open() {
    var object = this;

    this.dropDownToggle.setStatus(true);
    this.dropDown.scrollIntoView({ block: "nearest", inline: "nearest" });
    this.clickOutsideListener = connectClickOutsideListener(this.element, function (event) { object.toggleForm(); });

    this.focus();
  }

  recalculate() {
    const values = new Array();

    for (const option of this.options)
      if (option.selected)
        values.push(option.value);

    this.updateFieldValue(values.join(","));
    this.reloadCaption();
  }

  reloadCaption() {
    const captions = new Array();

    for (const option of this.options)
      if (option.selected)
        captions.push(option.element.innerHTML);

    this.label.innerHTML = captions.join(", ");
  }

  selectAll() {
    for (var option of this.options)
      option.setStatus(true);

    this.recalculate();
  }

  setValue(value) {
    if (value !== this.input.value) {
      const values = value.split(",");

      for (const option of this.options)
        if (option.selected !== values.includes(option.value))
          option.toggle();

      this.reloadCaption();
      this.updateFieldValue(value);
    }
  }

  updateFieldValue(value) {
    this.input.value = value;
    this.input.dispatchEvent(new Event("input"));
    this.input.dispatchEvent(new Event("change"));
  }

  toggle(option) {
    option.toggle();
    this.recalculate();
  }

  toggleForm() {
    if (this.isOpen())
      this.close();
    else
      this.open();
  }
}

function MultipleSelectOption(element) {
  this.setStatus = function (status) {
    this.selected = status;

    this.classSwitch.setStatus(status);
    this.element.setAttribute("aria-selected", status);
  }

  this.toggle = function () {
    this.setStatus(!this.selected);
  }

  this.element = element;
  this.element.tabIndex = -1;

  this.value = this.element.dataset.Value;
  this.selected = element.classList.contains("Selected");

  this.classSwitch = new HtmlClassSwitch(this.element, "Selected");
  this.selected = this.classSwitch.getStatus();
}

interactivityRegistration.register("MultipleSelectDropDown", function (element) { return new MultipleSelectDropDown(element); });
