class FileUploadStatusChangedEvent {
  constructor(upload) {
    this.upload = upload;
  }
}

class UploadCenter {
  constructor() {
    this.fileStore = new FileStore();
    this.initialize();

    const query = window.matchMedia('(max-width: 50em)');
    query.addEventListener(
      'change',
      (event) => {
        if (event.matches)
          this.collapsed.setStatus(true);
      }
    );

    if (query.matches)
      this.collapsed.setStatus(true);
  }

  initialize() {
    this.title = document.createElement("span");
    this.title.className = "Title";

    // TODO: Add a proper label.
    this.confirmationWindowElement = document.createElement("div");
    this.confirmationWindow = new ConfirmationWindowComponent(this.confirmationWindowElement, "Some uploads have failed. Are you sure you want to close all?");

    this.toggleButton = document.createElement("button");
    this.toggleButton.classList = "Toggle";
    this.toggleButton.addEventListener("click", () => this.toggle());

    this.closeAllButton = document.createElement("button");
    this.closeAllButton.classList = "Close";
    this.closeAllButton.addEventListener(
      "click",
      () => {
        if (this.shouldConfirmClose())
          this.confirmationWindow.show(() => this.closeAll());
        else
          this.closeAll();
      }
    );

    this.restartAllButton = document.createElement("button");
    this.restartAllButton.classList = "Restart";
    this.restartAllButton.addEventListener("click", () => this.restartAll());

    this.toolbar = document.createElement("span");
    this.toolbar.className = "Toolbar";
    this.toolbar.appendChild(this.title);
    this.toolbar.appendChild(this.toggleButton);
    this.toolbar.appendChild(this.closeAllButton);
    this.toolbar.appendChild(this.restartAllButton);

    this.table = document.createElement("table");

    this.element = document.createElement("div");
    this.element.classList.add("UploadCenter");
    this.element.appendChild(this.toolbar);
    this.element.appendChild(this.table);
    this.element.appendChild(this.confirmationWindowElement);

    this.collapsed = new HtmlClassSwitch(this.element, "Collapsed");

    const main = document.getElementById("main");
    main.appendChild(this.element);

    this.uploads = new Map();
  }

  createRow(upload) {
    const row = this.table.insertRow();
    row.className = State.toText(upload.state);
    row.dataset.Id = upload.id;

    let cell = row.insertCell();
    cell.classList.add("Icon");
    cell.appendChild(document.createElement("span"));

    cell = row.insertCell();
    cell.classList.add("Name");
    cell.appendChild(document.createTextNode(upload.name));

    cell = row.insertCell();
    cell.classList.add("Target");
    cell.appendChild(document.createTextNode(upload.target));

    cell = row.insertCell();
    cell.classList.add("Size")
    cell.appendChild(document.createTextNode((upload.size / 1000000).toFixed(2) + "MB"));

    const progress = document.createElement("span");
    progress.style.width = 100 * upload.progress + "%";

    const container = document.createElement("span");
    container.appendChild(progress);

    cell = row.insertCell();
    cell.classList.add("Progress");
    cell.appendChild(container);

    cell = row.insertCell();
    cell.classList.add("Actions");

    let action = document.createElement("button");
    action.className = "Abort";
    action.addEventListener("click", () => this.abortUpload(upload.id));

    cell.appendChild(action);

    action = document.createElement("button");
    action.className = "Restart";
    action.addEventListener("click", () => this.restartUpload(upload.id));

    cell.appendChild(action);

    cell = row.insertCell();
    cell.classList.add("Problems");

    this.fillProblemsCell(row, upload);
  }

  getRow(id) {
    return new DomQuery(this.table).getDescendant((element) => element.dataset.Id == id);
  }

  deleteRow(id) {
    this.uploads.delete(id);

    const row = this.getRow(id);

    if (row) {
      const index = Array.from(this.table.rows).indexOf(row);

      if (index !== -1)
        this.table.deleteRow(index);
    }
  }

  fillProblemsCell(row, upload) {
    if (!Array.isArray(upload.problems))
      upload.problems = [upload.problems]

    row.childNodes[6].innerHTML = "";

    upload.problems.forEach(
      (problemJson) => {
        let problem = document.createElement("span");
        problem.innerText = problemJson.Message;
        problem.classList.add("Problem");

        if ("Severity" in problemJson)
          problem.classList.add(problemJson.Severity.replace(/\s/g, ""));

        row.childNodes[6].appendChild(problem);
      }
    );
  }

  updateRow(upload) {
    let row = this.getRow(upload.id);

    row.childNodes[2].childNodes[0].innerText = upload.target;
    row.childNodes[4].childNodes[0].childNodes[0].style.width = 100 * upload.progress + "%";

    this.fillProblemsCell(row, upload);

    row.className = State.toText(upload.state);
  }

  async abortUpload(id) {
    // TODO: This operation should happen in a single transaction.
    const file = await this.fileStore.get(parseInt(id));
    file.state = State.Aborted;

    await this.fileStore.update(file);
    this.serviceWorker.controller.postMessage(file);
  }

  async restartUpload(id) {
    // TODO: This operation should happen in a single transaction.
    const file = await this.fileStore.get(parseInt(id));

    if (file.location !== null) {
      file.state = State.Prepared;
      file.progress = 0.05;
      file.problems = [];
    }
    else {
      file.state = State.Initial;
      file.progress = 0;
      file.problems = [];
    }

    await this.fileStore.update(file);
    this.serviceWorker.controller.postMessage(file);
  }

  updateState() {
    if (this.table.rows.length == 0)
      this.element.classList.remove("Expanded");
    else {
      if (this.isActive()) {
        this.element.classList.add("Active");
        this.element.classList.add("Expanded");
      }
      else
        this.element.classList.remove("Active");

      const query = new DomQuery(this.element);
      const closeButton = query.getDescendant(WithClass("Close"));

      if (closeButton) {
        if (this.isClosable())
          closeButton.removeAttribute("disabled");
        else
          closeButton.setAttribute("disabled", "true");
      }

      const restartButton = query.getDescendant(WithClass("Restart"));

      if (restartButton) {
        if (this.isRestartable())
          restartButton.removeAttribute("disabled");
        else
          restartButton.setAttribute("disabled", "true");
      }
    }
  }

  isActive() {
    const completedUploads = this.uploads.values().reduce((sum, upload) => sum + (upload.state === State.Successful || upload.state === State.Aborted), 0);
    return this.uploads.size > completedUploads;
  }

  isClosable() {
    const finishedUploads = this.uploads.values().reduce((sum, upload) => sum + (upload.state === State.Successful || upload.state === State.Error || upload.state === State.Aborted), 0);
    return this.uploads.size === finishedUploads;
  }

  isRestartable() {
    const errors = this.uploads.values().reduce((sum, upload) => sum + (upload.state === State.Error), 0);
    return errors > 0;
  }

  shouldConfirmClose() {
    const errors = this.uploads.values().reduce((sum, upload) => sum + (upload.state === State.Error), 0);
    return errors > 0;
  }

  restartAll() {
    this.uploads.values().forEach(
      (upload) => {
        if (upload.state === State.Error)
          this.restartUpload(upload.id);
      }
    );
  }

  closeAll () {
    this.uploads.values().forEach(
      (upload) => {
        if (upload.state === State.Error)
          this.abortUpload(upload.id);

        this.deleteRow(upload.id);
      }
    );

    this.updateState();
  }

  toggle() {
    this.collapsed.toggle();
  }

  reload(upload) {
    if (this.uploads.has(upload.id)) {
      this.uploads.set(upload.id, upload);

      let row = this.getRow(upload.id);
      row.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });

      this.updateRow(upload);
      this.updateTitle();

      distributeEvent(new FileUploadStatusChangedEvent(upload));
    }
  }

  reloadAll(uploads) {
    for (const upload of uploads) {
      if (this.uploads.has(upload.id))
        this.updateRow(upload);
      else
        this.createRow(upload)

      this.uploads.set(upload.id, upload);
    }

    this.updateTitle();
  }

  updateTitle() {
    const query = new DomQuery(this.table);

    const total = this.table.rows.length - query.getDescendants(WithClass("Aborted")).length - query.getDescendants(WithClass("Forbidden")).length;
    const count = query.getDescendants(WithClass("Successful")).length;

    // TODO: Use proper translated caption.
    if (total === 0)
      this.title.innerText = "";
    else
      this.title.innerText = "Uploads: " + count + " / " + total;
  }

  connect(serviceWorker) {
    if (this.listener !== undefined)
      this.serviceWorker.removeEventListener("message", this.listener);

    this.listener = (event) => {
      const data = event.data;

      if (event.data.class === "Files") {
        if (Array.isArray(data.value))
          this.reloadAll(data.value);
        else
          this.reload(data.value);
      }

      this.updateState();
    }

    this.serviceWorker = serviceWorker;
    this.serviceWorker.addEventListener("message", this.listener);
  }
}
